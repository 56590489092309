var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderType !== 'HOUSECONNECTION'),expression:"orderType !== 'HOUSECONNECTION'"}],attrs:{"label":"Dokumente Diensteauftrag"}},[_c('div',{staticClass:"input-row"},[_c('v-select',{attrs:{"items":_vm.fileTypesServiceOrder,"disabled":_vm.disabled,"dense":"","outlined":"","item-text":"name","item-value":"value","label":"Dokumententyp","return-object":""},model:{value:(_vm.fileTypeServiceOrder),callback:function ($$v) {_vm.fileTypeServiceOrder=$$v},expression:"fileTypeServiceOrder"}}),_c('v-file-input',{attrs:{"disabled":_vm.disabled,"color":"primary","accept":".pdf","counter":"","label":"File input","dense":"","placeholder":"PDF Auswählen","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},on:{"change":function($event){return _vm.handleOnUploadServiceOrder()}},model:{value:(_vm.fileServiceOrder),callback:function ($$v) {_vm.fileServiceOrder=$$v},expression:"fileServiceOrder"}})],1),_c('UploadedDocumentsTable',{attrs:{"documents":_vm.uploadedFilesServiceOrder,"documentsCanBeDeleted":true},on:{"deleteDocument":function($event){{
          _vm.uploadedFilesServiceOrder = _vm.removeDocumentsWithId(
            _vm.uploadedFilesServiceOrder,
            $event
          );
          _vm.$emit(
            'uploadedFilesServiceOrderChanged',
            _vm.uploadedFilesServiceOrder
          );
        }}}})],1),_c('DisplayContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderType !== 'SERVICE'),expression:"orderType !== 'SERVICE'"}],attrs:{"label":'Dokumente Hausanschlussauftrag'}},[_c('div',{staticClass:"input-row"},[_c('v-select',{attrs:{"items":_vm.fileTypesHouseConnectionOrder,"disabled":_vm.disabled,"dense":"","outlined":"","item-text":"name","item-value":"value","label":"Dokumententyp","return-object":""},model:{value:(_vm.fileTypeHouseConnectionOrder),callback:function ($$v) {_vm.fileTypeHouseConnectionOrder=$$v},expression:"fileTypeHouseConnectionOrder"}}),_c('v-file-input',{attrs:{"disabled":_vm.disabled,"color":"primary","accept":".pdf","counter":"","label":"File input","dense":"","placeholder":"PDF Auswählen","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},on:{"change":function($event){return _vm.handleOnUploadHouseConnectionOrder()}},model:{value:(_vm.fileHouseConnectionOrder),callback:function ($$v) {_vm.fileHouseConnectionOrder=$$v},expression:"fileHouseConnectionOrder"}})],1),_c('UploadedDocumentsTable',{attrs:{"documents":_vm.uploadedFilesHouseConnectionOrder,"documentsCanBeDeleted":true},on:{"deleteDocument":function($event){{
          _vm.uploadedFilesHouseConnectionOrder = _vm.removeDocumentsWithId(
            _vm.uploadedFilesHouseConnectionOrder,
            $event
          );
          _vm.$emit(
            'uploadedFilesHouseConnectionOrderChanged',
            _vm.uploadedFilesHouseConnectionOrder
          );
        }}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }