var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":"Kontaktdaten"}},[_c('CustomerInformationSelection',{attrs:{"previouslySelectedCustomerInformation":_vm.customerData.customerInformation,"disabled":_vm.disabled,"displayError":_vm.displayError},on:{"customerInformationChanged":function($event){_vm.customerData.customerInformation = $event.value;
        _vm.validationErrors.customerInformation = $event.validationErrors;
        _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{attrs:{"label":"Installationsadresse"}},[_c('InstallationAddressSelection',{attrs:{"displayError":_vm.displayError,"previouslySelectedInstallationAddress":_vm.customerData.installationAddress,"customerInformation":_vm.customerData.customerInformation,"installationAddressIsServiceOrderInvoiceAddress":_vm.installationAddressIsServiceOrderInvoiceAddress,"installationAddressIsHouseConnectionOrderInvoiceAddress":_vm.installationAddressIsHouseConnectionOrderInvoiceAddress,"orderType":_vm.orderType,"disabled":true,"validationErrorsCustomerInformation":_vm.validationErrors.customerInformation},on:{"installationAddressChanged":function($event){{
          _vm.customerData.installationAddress = $event.value;
          _vm.validationErrors.installationAddress = $event.validationErrors;
          _vm.validationOngoing.installationAddress = $event.validationOngoing;
          _vm.emitCurrentState();
        }}}})],1),(_vm.ibanCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Lastschriftmandat","selectionConfiguration":{
      label: 'Zahlung per Lastschriftmandat',
      isSelected: _vm.customerData.sepaMandateIsSelected,
      isFoldable: true,
      disabled: _vm.disabled
    }},on:{"update:isSelected":function($event){_vm.customerData.sepaMandateIsSelected = $event;
      _vm.validationErrors.sepaMandate = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('SepaMandateSelection',{staticClass:"margin-top-1",attrs:{"customerInformation":_vm.customerData.customerInformation,"previouslySelectedSepaMandate":_vm.customerData.sepaMandate,"disabled":_vm.disabled,"displayError":_vm.displayError},on:{"sepaMandateChanged":function($event){_vm.customerData.sepaMandate = $event.value;
        _vm.validationErrors.sepaMandate = $event.validationErrors;
        _vm.emitCurrentState();}}})],1):_vm._e(),(_vm.billingAddressCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Rechnungsadresse","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.invoiceAddressIsSelected,
      isFoldable: true,
      disabled: _vm.disabled
    }},on:{"update:isSelected":function($event){_vm.customerData.invoiceAddressIsSelected = $event;
      _vm.validationErrors.invoiceAddress = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('InvoiceAddressSelection',{attrs:{"displayError":_vm.displayError,"previouslySelectedInvoiceAddress":_vm.customerData.invoiceAddress,"invoiceAddressIsServiceOrderInvoiceAddress":_vm.invoiceAddressIsServiceOrderInvoiceAddress,"invoiceAddressIsHouseConnectionOrderInvoiceAddress":_vm.invoiceAddressIsHouseConnectionOrderInvoiceAddress,"disabled":_vm.disabled},on:{"invoiceAddressChanged":function($event){_vm.customerData.invoiceAddress = $event.value;
        _vm.validationErrors.invoiceAddress = $event.validationErrors;
        _vm.validationOngoing.invoiceAddress = $event.validationOngoing;
        _vm.emitCurrentState();}}})],1):_vm._e(),(_vm.contactAddressCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Kontaktadresse Diensteauftrag","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.contactAddressIsSelected,
      isFoldable: true,
      disabled: _vm.disabled
    }},on:{"update:isSelected":function($event){_vm.customerData.contactAddressIsSelected = $event;
      _vm.validationErrors.contactAddress = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('AddressInformationSelection',{attrs:{"displayError":_vm.displayError,"previouslySelectedAddressInformation":_vm.customerData.contactAddress,"disabled":_vm.disabled},on:{"addressInformationChanged":function($event){{
          _vm.customerData.contactAddress = $event.value;
          _vm.validationErrors.contactAddress = $event.validationErrors;
          _vm.emitCurrentState();
        }}}})],1):_vm._e(),(_vm.deviationOwnerInformationCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Versandadresse","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.deviatingDeliveryAddressIsSelected,
      isFoldable: true,
      disabled: _vm.disabled
    }},on:{"update:isSelected":function($event){_vm.customerData.deviatingDeliveryAddressIsSelected = $event;
      _vm.validationErrors.deliveryAddress = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('AddressInformationSelection',{attrs:{"displayError":_vm.displayError,"previouslySelectedAddressInformation":_vm.customerData.deliveryAddress,"disabled":_vm.disabled},on:{"addressInformationChanged":function($event){{
          _vm.customerData.deliveryAddress = $event.value;
          _vm.validationErrors.deliveryAddress = $event.validationErrors;
          _vm.emitCurrentState();
        }}}})],1):_vm._e(),(_vm.deviationOwnerInformationCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Grundstückseigentümer","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.deviatingOwnerInformationIsSelected,
      isFoldable: true,
      disabled: _vm.disabled
    }},on:{"update:isSelected":function($event){_vm.customerData.deviatingOwnerInformationIsSelected = $event;
      _vm.validationErrors.deviatingOwnerInformation = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('OwnerSelection',{attrs:{"displayError":_vm.displayError,"previouslySelectedDeviatingOwnerInformation":_vm.customerData.deviatingOwnerInformation,"ownerAddressIsHouseConnectionOrderInvoiceAddress":_vm.ownerAddressIsHouseConnectionOrderInvoiceAddress,"disabled":_vm.disabled},on:{"ownerInformationChanged":function($event){{
          _vm.customerData.deviatingOwnerInformation = $event.value;
          _vm.validationErrors.deviatingOwnerInformation =
            $event.validationErrors;
          _vm.validationOngoing.deviatingOwnerInformation =
            $event.validationOngoing;

          _vm.emitCurrentState();
        }}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }