const itemizedBillTypes = {
  NO_ITEMIZED_BILLS: 'kein EVN',
  SHORT_ITEMIZED_BILLS: 'EVN mit verkürzter Darstellung',
  FULL_ITEMIZED_BILLS: 'EVN mit vollständiger Darstellung'
};

const invoiceShippingMethods = { EMAIL: 'Per E-Mail', POST: 'Per Post' };
const phoneBookRecordTypes = {
  NO_ENTRY: 'kein Eintrag',
  DEFAULT_ENTRY: 'Standardeintrag',
  USER_DEFINED_ENTRY: 'Abweichender Eintrag'
};
const storingPeriodsOfConnectionInformation = {
  SHIPPING_OF_BILL: 'Löschung nach Rechnungsversand',
  PERIOD_OF_EXAMINATION_DEADLINE:
    'Löschung nach Ablauf der Rechnungsprüfungsfrist (max. 6 Monate)'
};

export default {
  itemizedBillTypes,
  invoiceShippingMethods,
  phoneBookRecordTypes,
  storingPeriodsOfConnectionInformation
};
