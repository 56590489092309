<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <DisplayContainer :label="'Einzelverbindungsnachweise (EVN)'">
      <v-radio-group
        dense
        hide-details
        :value="serviceOrderOptions.itemizedBillsGenerationType"
        @change="
          serviceOrderOptions.itemizedBillsGenerationType = $event;

          emitCurrentState();
        "
      >
        <v-radio
          value="NO_ITEMIZED_BILLS"
          :label="itemizedBillTypes['NO_ITEMIZED_BILLS']"
        />
        <v-radio
          value="SHORT_ITEMIZED_BILLS"
          :label="itemizedBillTypes['SHORT_ITEMIZED_BILLS']"
        />
        <v-radio
          value="FULL_ITEMIZED_BILLS"
          :label="itemizedBillTypes['FULL_ITEMIZED_BILLS']"
        />
      </v-radio-group>
    </DisplayContainer>
    <DisplayContainer label="Telefonbucheintrag (kostenlos)">
      <div class="input-row">
        <v-radio-group
          :value="serviceOrderOptions.phoneBookOptions.phoneBookRecordType"
          dense
          hide-details
          @change="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType = $event;
            validationErrors.phoneBookOptions._validationErrors =
              checkMissingFieldsOfPhoneBookOptions(
                serviceOrderOptions.phoneBookOptions
              );
            if ($event === 'NO_ENTRY') {
              serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch = false;
            } else if ($event === 'DEFAULT_ENTRY') {
              serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                extractBasicCustomerInformation(customerInformation);
            }
            if ($event !== 'USER_DEFINED_ENTRY') {
              validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                { _validationErrors: [] };
            }
            emitCurrentState();
          "
        >
          <v-radio value="NO_ENTRY" :label="phoneBookRecordTypes['NO_ENTRY']" />
          <v-radio
            value="DEFAULT_ENTRY"
            :label="phoneBookRecordTypes['DEFAULT_ENTRY']"
          />
          <v-radio
            value="USER_DEFINED_ENTRY"
            :label="phoneBookRecordTypes['USER_DEFINED_ENTRY']"
          />
        </v-radio-group>
      </div>
      <div class="v-checkbox">
        <v-checkbox
          v-model="serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch"
          :hide-details="true"
          :disabled="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
              'NO_ENTRY'
          "
          dense
          label="Der Herausgabe von Teilnehmerdaten für die Rückwärtssuche anhand der
          Telefonnummern(n) wird zugestimmt."
          @change="emitCurrentState()"
        ></v-checkbox>
      </div>
      <DisplayContainer
        v-show="
          serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
            'NO_ENTRY'
        "
        label="Telefonbucheintrag"
        :isSubcomponent="true"
      >
        <CustomerInformationSelection
          v-show="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
              'DEFAULT_ENTRY'
          "
          :displayError="displayError"
          class="margin-top-1"
          :displayBasicInformation="true"
          :disabled="true"
          :previouslySelectedCustomerInformation="
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
              .customerInformation
          "
          @customerInformationChanged="
            {
              serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                $event.value;
              validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                $event.validationErrors;
              emitCurrentState();
            }
          "
        />
        <CustomerInformationSelection
          v-show="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
              'USER_DEFINED_ENTRY'
          "
          :displayError="displayError"
          class="margin-top-1"
          :displayBasicInformation="true"
          :previouslySelectedCustomerInformation="
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
              .customerInformation
          "
          @customerInformationChanged="
            {
              serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                $event.value;
              validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
                $event.validationErrors;
              emitCurrentState();
            }
          "
        />
        <AddressAutocomplete
          :previouslySelectedAddress="
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address
          "
          :disabled="true"
          @update="
            {
              serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address =
                $event.value;
              validationErrors.phoneBookOptions.phoneBookEntryAddress.address =
                $event.validationErrors;
              emitCurrentState();
            }
          "
        />
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer label="Rechnungsversand">
      <v-radio-group
        :value="serviceOrderOptions.invoiceShippingMethod"
        :disabled="!emailAddressForInvoiceIsAvailable"
        dense
        hide-details
        @change="
          {
            serviceOrderOptions.invoiceShippingMethod = $event;
            emitCurrentState();
          }
        "
      >
        <v-radio value="EMAIL" :label="invoiceShippingMethods['EMAIL']" />
        <v-radio value="POST" :label="invoiceShippingMethods['POST']" />
      </v-radio-group>
    </DisplayContainer>
    <DisplayContainer
      label="Löschung der Verkehrsdaten von Telefonverbindungen"
    >
      <v-radio-group
        :value="serviceOrderOptions.storingPeriodOfConnectionInformation"
        dense
        hide-details
        @change="
          serviceOrderOptions.storingPeriodOfConnectionInformation = $event;
          emitCurrentState();
        "
      >
        <v-radio
          value="SHIPPING_OF_BILL"
          :label="storingPeriodsOfConnectionInformation['SHIPPING_OF_BILL']"
        />
        <v-radio
          value="PERIOD_OF_EXAMINATION_DEADLINE"
          :label="
            storingPeriodsOfConnectionInformation[
              'PERIOD_OF_EXAMINATION_DEADLINE'
            ]
          "
        />
      </v-radio-group>
    </DisplayContainer>
    <DisplayContainer
      :label="'Information zu Produkten, Dienstleistungen und Unternehmensgeschehen'"
    >
      <v-checkbox
        v-model="serviceOrderOptions.allowAdvertisement"
        label="Beratungseinwilligung wurde erteilt"
        dense
        hide-details
        @change="
          serviceOrderOptions.allowAdvertisement = $event;
          emitCurrentState();
        "
      >
      </v-checkbox>
    </DisplayContainer>
    <DisplayContainer
      label="Neurufnummernvergabe"
      :selectionConfiguration="{
        label: 'Neurufnummernvergabe durchführen',
        isSelected:
          serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers,
        isFoldable: true,
        disabled: false
      }"
      @update:isSelected="
        serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers =
          $event;
        if ($event === true) {
          serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers = 1;
        } else {
          serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers = 0;
        }
        emitCurrentState();
      "
    >
      <div class="provider-flex-child">
        <v-select
          v-model="
            serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers
          "
          :items="allowedAmountsOfNewPhoneNumbers"
          dense
          hide-details
          label="Anzahl Rufnummern"
          @change="
            serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers =
              $event;
            emitCurrentState();
          "
        />
      </div>
      <DisplayContainer
        :isSubcomponent="true"
        label="Gewünschter Schalttermin *"
      >
        <DatePicker
          :errorMessage="dateErrorMessage"
          :minDate="currentDate"
          :value="
            serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate
          "
          disableWeekend
          label="Anschaltdatum"
          @input="
            serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate =
              $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
    </DisplayContainer>
    <DisplayContainer
      label="Portierung Durchführen"
      :selectionConfiguration="{
        label: 'Übernahme eines bestehenden Anschlusses inkl. Rufnummer',
        isSelected: serviceOrderOptions.performPhoneNumberPorting,
        isFoldable: true,
        disabled: false
      }"
      @update:isSelected="
        serviceOrderOptions.performPhoneNumberPorting = $event;
        if ($event === false) {
          serviceOrderOptions.portingDetails = null;
        }
        validationErrors.portingDetails = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <SelectPortingOptions
        :previouslySelectedPortingOptions="serviceOrderOptions.portingDetails"
        @portingOptionsChanged="
          serviceOrderOptions.portingDetails = $event.value;
          validationErrors.portingDetails = $event.validationErrors;
          emitCurrentState();
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import Textfield from '@/components/elements/GoetelInputVuetify.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import SelectPortingOptions from './optionsSelection/SelectPortingOptions.vue';
import { LocalDate } from '@js-joda/core';
import ServiceOrderOptionsUtil from './ServerOrderOptionsUtil.js';
import CustomerInformationSelection from './customerDataSelection/CustomerInformationSelection.vue';
import AddressAutocomplete from './customerDataSelection/AddressAutocomplete.vue';

import lodash from 'lodash';
import { hasErrors } from './ValidationError.js';
import ValidationErrorDisplay from './ValidationErrorDisplay.vue';
const defaultValues = {
  newPhoneNumberDetails: {
    provideNewPhoneNumbers: false,
    numberOfNewPhoneNumbers: 0,
    requestedProvidingDate: LocalDate.now().toString()
  },
  invoiceShippingMethod: 'EMAIL',
  itemizedBillsGenerationType: 'NO_ITEMIZED_BILLS',
  phoneBookOptions: {
    phoneBookRecordType: 'NO_ENTRY',
    phoneBookEntryAddress: {
      customerInformation: null,
      address: null
    },
    allowPhoneNumberSearch: false
  },
  performPhoneNumberPorting: false,
  portingDetails: null,
  storingPeriodOfConnectionInformation: 'SHIPPING_OF_BILL',
  allowMarketing: false,
  allowAdvertisement: false
};

export { defaultValues };
export default {
  name: 'ServiceOrderOptionsSelection',
  components: {
    DisplayContainer,
    Textfield,
    DatePicker,
    SelectPortingOptions,
    ValidationErrorDisplay,
    CustomerInformationSelection,
    AddressAutocomplete
  },
  props: {
    previouslySelectedServiceOrderOptions: {
      type: Object,
      required: false,
      default: null
    },
    customerInformation: { type: Object, required: true },
    installationAddress: {
      type: Object,
      required: true
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    },
    emailAddressForInvoiceIsAvailable: {
      type: Boolean,
      required: true
    },
    initializeFromCustomerInfo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    carrierListLoading: false,
    serviceOrderOptions: null,
    validationErrors: null,
    currentDate: LocalDate.now().toString(),
    allowedAmountsOfNewPhoneNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    emailAddressForInvoice: null,
    itemizedBillTypes: ServiceOrderOptionsUtil.itemizedBillTypes,
    invoiceShippingMethods: ServiceOrderOptionsUtil.invoiceShippingMethods,
    phoneBookRecordTypes: ServiceOrderOptionsUtil.phoneBookRecordTypes,
    storingPeriodsOfConnectionInformation:
      ServiceOrderOptionsUtil.storingPeriodsOfConnectionInformation,
    // error messages
    dateErrorMessage: null
  }),
  created: function () {
    if (
      this.previouslySelectedServiceOrderOptions &&
      !this.initializeFromCustomerInfo
    ) {
      this.serviceOrderOptions = lodash.cloneDeep(
        this.previouslySelectedServiceOrderOptions
      );
    } else {
      this.serviceOrderOptions = this.buildDefaultServiceOrderOptions(
        this.customerInformation,
        this.installationAddress
      );
    }
    if (
      this.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers > 0
    ) {
      this.serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers = true;
    } else {
      this.serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers = false;
    }

    if (!this.emailAddressForInvoiceIsAvailable) {
      this.serviceOrderOptions.invoiceShippingMethod = 'POST';
    }

    this.validationErrors = this.buildInitialValidationErrors(
      this.serviceOrderOptions
    );

    this.emitCurrentState();
  },
  methods: {
    buildInitialValidationErrors(serviceOrderOptions) {
      return {
        _validationErrors: [],
        phoneBookOptions: this.buildInitialValidationErrorsPhoneBookOptions(
          serviceOrderOptions.phoneBookOptions
        ),
        portingDetails: null
      };
    },

    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildInitialValidationErrorsPhoneBookOptions(phoneBookOptions) {
      const missingFields =
        this.checkMissingFieldsOfPhoneBookOptions(phoneBookOptions);
      const phoneBookEntryAddressErrors = {
        _validationErrors: [],
        customerInformation: null,
        address: { _validationErrors: [] }
      };
      return {
        _validationErrors: missingFields,
        phoneBookEntryAddress: phoneBookEntryAddressErrors
      };
    },
    checkMissingFieldsOfPhoneBookOptions(phoneBookOptions) {
      let missingFieldsPhoneBookOptions = [];
      if (phoneBookOptions.phoneBookRecordType === 'USER_DEFINED_ENTRY') {
        if (
          phoneBookOptions.phoneBookEntryAddress === null ||
          phoneBookOptions.phoneBookEntryAddress === undefined
        ) {
          missingFieldsPhoneBookOptions = [
            'Es muss ein Nutzerdefinierter Telefonbucheintrag angegeben werden.'
          ];
        }
      }
      return missingFieldsPhoneBookOptions;
    },
    emitCurrentState() {
      this.$emit('serviceOrderOptionsChanged', {
        value: this.serviceOrderOptions,
        validationErrors: this.validationErrors
      });
    },
    buildDefaultServiceOrderOptions(customerInformation, installationAddress) {
      const options = lodash.cloneDeep(defaultValues);
      options.phoneBookOptions.phoneBookEntryAddress.customerInformation =
        this.extractBasicCustomerInformation(customerInformation);
      options.phoneBookOptions.phoneBookEntryAddress.address =
        lodash.cloneDeep(installationAddress);
      return options;
    },
    extractBasicCustomerInformation(customerInformation) {
      return {
        salutation: customerInformation.salutation,
        firstName: customerInformation.firstName,
        lastName: customerInformation.lastName,
        company: customerInformation.company
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.v-checkbox ::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}

.provider-flex-child {
  width: 10%;
}

.left-selection {
  color: grey;
  grid-area: left;
  justify-self: start;
}

.right-selection {
  color: var(--goe-fontColor-highlight);

  grid-area: right;
  justify-self: end;
}
</style>
