<template>
  <div class="components-grid">
    <v-stepper
      :alt-labels="true"
      :value="disabled ? -1 : context.currentHighestAllowedStep"
    >
      <v-stepper-header>
        <template v-for="(step, index) in context.stepperList">
          <template>
            <v-stepper-step
              :key="index"
              :complete="
                disabled ? false : context.currentHighestAllowedStep >= index
              "
              :rules="[() => rules(index)]"
              :step="index + 1"
              edit-icon="mdi-check"
              :editable="
                disabled ? false : context.currentHighestAllowedStep >= index
              "
              @click="
                if (!disabled) {
                  updateCurrentStep(index);
                }
              "
            >
              <div
                :style="
                  context.currentStepIndex === index ? 'font-weight: 500' : ''
                "
                class="font-size-step"
              >
                {{ step.step }}
              </div>
            </v-stepper-step>
            <v-divider
              v-if="index < context.stepperList.length - 1"
              :key="`${index}-divider`"
            ></v-divider>
          </template>
        </template>
      </v-stepper-header>
      <template v-if="propertyResult && initialAddress && activeDistribution">
        <v-alert
          dense
          text
          small
          border="left"
          type="success"
          class="ma-2"
        >
          Installationsadresse:
          <b>
            {{ initialAddress.street }} {{ initialAddress.houseNumber
            }}{{ initialAddress.houseNumberSupply }},
            {{ initialAddress.zipCode }} {{ initialAddress.city }}
            <template
              v-if="propertyResult.district"
            >({{ propertyResult.district }})</template>
          </b>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                class="mx-3"
                outlined
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
                @click="
                  (orderEntryStarted = false),
                  (activeDistribution = false),
                  updateCurrentStep(0)
                "
              >
                <v-icon>mdi-delete-empty</v-icon>
              </v-btn>
            </template>
            <span>Adresse löschen.</span>
          </v-tooltip>
          <span class="float-right">
            AGS: <b>{{ propertyResult.ags27 }}</b>
            <span class="px-2">|</span>
            Projekt-ID: <b>{{ propertyResult.projectId }}</b>
          </span>
        </v-alert>
        <v-alert
          dense
          text
          small
          border="left"
          type="info"
          class="mx-2 mb-2 agency-alert"
        >
          <v-row>
            <v-col>
              <b>Vertragszuordnung:</b>
            </v-col>
            <v-col>&nbsp;</v-col>
            <v-col>
              <v-text-field
                v-model="agencyCompany"
                label="Firma"
                required
                dense
                light
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="agencyChannelId"
                :items="agencyChannels"
                :item-text="getAgencyItemText"
                item-value="ID"
                key-name="ID"
                label="Kanal"
                dense
                light
              ></v-select>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="agencyEmployeeId"
                :items="agencyEmployees"
                :item-text="getAgencyItemText"
                item-value="ID"
                key-name="ID"
                label="Mitarbeiter-ID"
                dense
                light
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-alert>
      </template>
    </v-stepper>
    <div v-if="!context.backendCallActive" class="process-step-wrapper">
      <template v-if="!orderEntryStarted && !propertyResult">
        <v-row>
          <v-col md="3"></v-col>
          <v-col md="6">
            <v-alert
              text
              border="left"
              type="error"
              class="my-4"
            >
              <h3>
                Bitte nur Sonderfälle wie z.B. Kommunalverträge über die
                SPOT-Auftragserfassung erfassen.<br />
                Standard Privatkundenverträge werden bitte immer über
                <a
                  href="https://bestellung.goetel.de/"
                  target="_blank"
                >eCommerce</a>
                erfasst.
              </h3>
            </v-alert>
          </v-col>
          <v-col md="3"></v-col>
        </v-row>
      </template>
      <AvailabilityForm
        v-if="!orderEntryStarted"
        :centered="true"
        @updatePropertyResult="updatePropertyResult"
      />
      <v-btn
        v-if="!orderEntryStarted && activeDistribution"
        color="primary"
        x-large
        class="my-4"
        @click="orderEntryStarted = true"
      >
        <v-icon>mdi-file-edit</v-icon>
        Auftragserfassung starten
      </v-btn>
      <template v-if="activeDistribution && orderEntryStarted">
        <OrderTypeSelection
          v-if="currentState.matches('selectOrderType')"
          :disabled="disabled"
          :previouslySelectedOrderType="context.selectedOrderType"
          @orderTypeChanged="sendData('UPDATE_ORDER_TYPE', $event)"
        />
        <CustomerDataSelection
          v-if="currentState.matches('selectCustomerData')"
          :key="'selectCustomerData'"
          :disabled="disabled"
          :displayError="displayErrors(context.currentStepIndex)"
          :orderType="context.selectedOrderType"
          :previouslySelectedCustomerData="context.selectedCustomerData"
          :customerInstallAddress="customerInstallAddress"
          @customerDataChanged="sendData('UPDATE_CUSTOMER_DATA', $event)"
        />
        <SignatureDateSelection
          v-if="currentState.matches('selectServiceOrderProducts')"
          :disabled="disabled"
          :displayError="displayErrors(context.currentStepIndex)"
          :previouslySelectedSignatureDate="
            context.orderDate ? context.orderDate : ''
          "
          @signatureDateChanged="sendData('UPDATE_ORDER_DATE', $event)"
        />
        <ServiceOrderProductSelection
          v-if="currentState.matches('selectServiceOrderProducts')"
          :displayError="displayErrors(context.currentStepIndex)"
          :installationAddress="
            context.selectedCustomerData.installationAddress
          "
          :previouslySelectedProductDate="
            context.orderDate ? context.orderDate : ''
          "
          :previouslySelectedProducts="context.selectedServiceOrderProducts"
          @productSelectionChanged="
            sendData('UPDATE_SERVICE_ORDER_PRODUCTS', $event)
          "
        />
        <SignatureDateSelection
          v-if="currentState.matches('selectHouseConnectionOrderProduct')"
          :disabled="disabled"
          :displayError="displayErrors(context.currentStepIndex)"
          :previouslySelectedSignatureDate="
            context.orderDate ? context.orderDate : ''
          "
          @signatureDateChanged="sendData('UPDATE_ORDER_DATE', $event)"
        />
        <HouseConnectionOrderProductSelection
          v-if="currentState.matches('selectHouseConnectionOrderProduct')"
          :displayError="displayErrors(context.currentStepIndex)"
          :installationAddress="
            context.selectedCustomerData.installationAddress
          "
          :previouslySelectedProduct="
            context.selectedHouseConnectionOrderProduct
          "
          :previouslySelectedProductDate="
            context.orderDate ? context.orderDate : ''
          "
          @productSelectionChanged="
            sendData('UPDATE_HOUSE_CONNECTION_ORDER_PRODUCT', $event)
          "
        />
        <ServiceOrderOptionsSelection
          v-if="currentState.matches('selectOptions')"
          :displayError="displayErrors(context.currentStepIndex)"
          :previouslySelectedServiceOrderOptions="
            context.selectedServiceOrderOptions
          "
          :customerInformation="
            context.selectedCustomerData.customerInformation
          "
          :installationAddress="
            context.selectedCustomerData.installationAddress
          "
          :emailAddressForInvoiceIsAvailable="
            context.emailAddressForServiceOrderInvoiceIsAvailable
          "
          :initializeFromCustomerInfo="context.fromHumanTask"
          @serviceOrderOptionsChanged="
            sendData('UPDATE_SERVICE_ORDER_OPTIONS', $event)
          "
        />
        <OrderDocumentsUpload
          v-if="currentState.matches('manageDocuments')"
          :orderType="context.selectedOrderType"
          :previouslyUploadedFilesHouseConnectionOrder="
            context.uploadedFilesHouseConnectionOrder
          "
          :previouslyUploadedFilesServiceOrder="
            context.uploadedFilesServiceOrder
          "
          @uploadedFilesHouseConnectionOrderChanged="
            sendData('UPDATE_UPLOADED_FILES_HOUSE_CONNECTION_ORDER', $event)
          "
          @uploadedFilesServiceOrderChanged="
            sendData('UPDATE_UPLOADED_FILES_SERVICE_ORDER', $event)
          "
        />
        <OrderSummary
          v-if="currentState.matches('confirmSelection')"
          :customerData="context.selectedCustomerData"
          :orderDate="context.orderDate"
          :orderType="context.selectedOrderType"
          :previouslyUploadedFilesHouseConnectionOrder="
            context.uploadedFilesHouseConnectionOrder
          "
          :previouslyUploadedFilesServiceOrder="
            context.uploadedFilesServiceOrder
          "
          :selectedHouseConnectionOrderProduct="
            context.selectedHouseConnectionOrderProduct
          "
          :selectedServiceOrderOptions="context.selectedServiceOrderOptions"
          :selectedServiceOrderProducts="context.selectedServiceOrderProducts"
          :validationErrorsOrderDate="context.validationErrorsOrderDate"
          :validationErrorsSelectedCustomerData="
            context.validationErrorsSelectedCustomerData
          "
          :validationErrorsSelectedHouseConnectionOrderProduct="
            context.validationErrorsSelectedHouseConnectionOrderProduct
          "
          :validationErrorsSelectedServiceOrderOptions="
            context.validationErrorsSelectedServiceOrderOptions
          "
          :validationErrorsSelectedServiceOrderProducts="
            context.validationErrorsSelectedServiceOrderProducts
          "
        />
      </template>
    </div>
    <Loader v-else />

    <div
      v-if="activeDistribution && orderEntryStarted"
      class="align-buttons margin-bottom-2"
    >
      <v-row>
        <v-col v-if="context.showDisplayBackButton === true">
          <v-btn
            v-if="context.showDisplayBackButton === true"
            :disabled="context.backendCallActive || disabled"
            class="button-left"
            color="primary"
            large
            @click="jumpToPreviousStep()"
          >
            Zurück
          </v-btn>
        </v-col>
        <v-col>
          <v-dialog
            v-model="dialog"
            persistent
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="
                  !context.orderCanBeCanceled ||
                    context.backendCallActive ||
                    disabled
                "
                class="button-middle-left"
                color="primary"
                large
                v-bind="attrs"
                v-on="on"
              >
                Abbrechen
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Wollen Sie den wirklich Auftrag löschen?
              </v-card-title>
              <v-card-text>
                Sind Sie sicher, dass der Auftrag gelöscht werden soll?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red"
                  text
                  @click="dialog = false"
                >
                  <v-icon dark left>
                    mdi-delete
                  </v-icon>
                  Löschen
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="sendData('CANCEL_ORDER', currentState.value)"
                >
                  Abbrechen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-tooltip
            :disabled="context.orderCanBeSaved"
            class="button-middle-right"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="button-middle-right" v-on="on">
                <v-btn
                  :disabled="
                    context.validationOngoing ||
                      !context.orderCanBeSaved ||
                      context.backendCallActive ||
                      disabled
                  "
                  :loading="context.validationOngoing"
                  color="primary"
                  large
                  v-bind="attrs"
                  @click="sendData('SAVE_ORDER', currentState.value)"
                >
                  Speichern
                </v-btn>
              </div>
            </template>
            <div>
              <ul>
                <li v-if="context.validationOngoing">
                  Validierung der Installationsadresse aktiv
                </li>
                <div v-else>
                  <li
                    v-if="hasErrors(context.validationErrorsSelectedOrderType)"
                  >
                    Es muss ein Auftragstyp ausgewählt werden.
                  </li>
                  <li
                    v-if="
                      !context.validationErrorsSelectedCustomerData ||
                        (context.validationErrorsSelectedCustomerData &&
                          hasErrors(
                            context.validationErrorsSelectedCustomerData
                              .customerInformation
                          ))
                    "
                  >
                    Die Kontaktdaten sind fehlerhaft oder unvollständig.
                  </li>
                  <li
                    v-if="
                      !context.validationErrorsSelectedCustomerData ||
                        (context.validationErrorsSelectedCustomerData &&
                          hasErrors(
                            context.validationErrorsSelectedCustomerData
                              .installationAddress
                          ))
                    "
                  >
                    Die Installationsadresse ist fehlerhaft oder unvollständig.
                  </li>
                </div>
              </ul>
            </div>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-btn
            :disabled="
              context.validationOngoing ||
                context.forwardButtonDisabled ||
                context.backendCallActive ||
                disabled
            "
            :loading="context.validationOngoing"
            class="button-right"
            color="primary"
            large
            @click="jumpToNextStep()"
          >
            {{ context.nextStepButtonLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ServiceOrderProductSelection from './ServiceOrderProductSelection.vue';
import SignatureDateSelection from './SignatureDateSelection.vue';
import CustomerDataSelection from './CustomerDataSelection.vue';
import OrderSummary from './OrderSummary.vue';
import OrderTypeSelection from './OrderTypeSelection.vue';
import ServiceOrderOptionsSelection from './ServiceOrderOptionsSelection.vue';
import HouseConnectionOrderProductSelection from './HouseConnectionOrderProductSelection.vue';
import OrderDocumentsUpload from './OrderDocumentsUpload.vue';
import Loader from '@/components/Loader.vue';
import lodash from 'lodash';
import AvailabilityForm from '@/components/constructionProjects/AvailabilityForm.vue';
// import XStateUtil from '@/util/XStateUtil.js';
import { assign, createMachine, interpret } from 'xstate';

import { extractErrors, hasErrors } from './ValidationError.js';

import { HTTP, POLL } from '@/main/httpClient.js';
import {
  buildContactAddressServiceOrder,
  buildDeliveryAddress,
  buildInstallationAddress,
  buildInvoiceAddressHouseConnectionOrder,
  buildInvoiceAddressServiceOrder,
  buildOwnerAddress,
  buildAddressDto
} from './CustomerDataUtil.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'OrderProcessDialogue',
  components: {
    ServiceOrderProductSelection,
    HouseConnectionOrderProductSelection,
    CustomerDataSelection,
    OrderTypeSelection,
    OrderDocumentsUpload,
    OrderSummary,
    ServiceOrderOptionsSelection,
    SignatureDateSelection,
    Loader,
    AvailabilityForm
  },
  props: {
    previousDataSelection: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    viewStateService: null,
    currentState: null,
    context: null,
    previousStep: 0,
    editable: false,
    complete: false,
    dialog: false,
    propertyResult: undefined,
    initialAddress: undefined,
    customerInstallAddress: undefined,
    activeDistribution: false,
    orderEntryStarted: false,
    agencyCompany: 'Goetel',
    agencyCompandyId: 100,
    agencyChannels: [],
    agencyChannelId: null,
    agencyEmployees: [],
    agencyEmployeeId: null
  }),
  created() {
    const newFsm = buildFsm(this, this.previousDataSelection);
    // Interpret the machine and store it in data
    this.viewStateService = interpret(newFsm);
    // Start with the machine's initial state
    this.currentState = newFsm.initialState;
    // Start with the machine's initial context
    this.context = newFsm.context;
    // Start service on component creation
    this.viewStateService
      .onTransition((state) => {
        // Update the current state component data property with the next state
        this.currentState = state;
        // Update the context component data property with the updated context
        this.context = state.context;
      })
      .start();
    this.getDistributionChannels();
    this.getDistributionEmployees();
  },
  methods: {
    updatePropertyResult(result, address, distribution) {
      this.propertyResult = result;
      this.initialAddress = address;
      this.activeDistribution = distribution;
      // currentObj = Object.assign(currentObj, newValues);
      this.customerInstallAddress = {
        alternativeLocationDescriptionIsSelected: false,
        zipCode: this.initialAddress.zipCode,
        city: this.initialAddress.city,
        street: this.initialAddress.street,
        houseNumber: this.initialAddress.houseNumber,
        houseNumberSupplement: this.initialAddress.houseNumberSupplement,
        alternativeLocationDescription: null
      };
    },
    extractErrors(validationErrors) {
      return extractErrors(validationErrors);
    },
    // Send events to the service
    send(event) {
      this.viewStateService.send(event);
    },
    incrementStepper() {
      this.currentStep = this.currentStep++;
    },
    sendData(updateEvent, payload) {
      this.dialog = false;
      this.send({ type: updateEvent, payload: payload });
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    cancelProcess(taskId) {
      return HTTP.post(`/orderTask/finalizeOrder/cancelOrder/${taskId}`);
    },
    saveHumanTask(context) {
      return HTTP.post(
        `/orderTask/finalizeOrder/saveOrder/${context.taskId}`,
        this.buildOrderDto(context)
      );
    },
    saveNewProcess(context) {
      return HTTP.post('/order/storeOrder', this.buildOrderDto(context));
    },
    finishProcessStartHumanTask(context) {
      return HTTP.post(
        `/orderTask/finalizeOrder/startProcess/${context.taskId}`,
        this.buildOrderDto(context)
      );
    },
    startProcess(context) {
      return HTTP.post('/order/startFromSpotForm', this.buildOrderDto(context));
    },
    rules(index) {
      const fsmStepOfIndex = this.context.stepperList[index].fsmStep;
      const validationErrorsForIndex =
        this.retrieveValidationErrorsOfState(fsmStepOfIndex);
      return !hasErrors(validationErrorsForIndex);
    },
    retrieveValidationErrorsOfState(fsmStep) {
      // retrieves the validation erros of fsmStep
      if (
        fsmStep === 'selectOrderType' &&
        hasErrors(this.context.validationErrorsSelectedOrderType)
      ) {
        return this.context.validationErrorsSelectedOrderType;
      } else if (
        fsmStep === 'selectCustomerData' &&
        hasErrors(this.context.validationErrorsSelectedCustomerData)
      ) {
        return this.context.validationErrorsSelectedCustomerData;
      } else if (
        fsmStep === 'selectServiceOrderProducts' &&
        hasErrors(this.context.validationErrorsSelectedServiceOrderProducts)
      ) {
        return this.context.validationErrorsSelectedServiceOrderProducts;
      } else if (
        fsmStep === 'selectHouseConnectionOrderProduct' &&
        hasErrors(
          this.context.validationErrorsSelectedHouseConnectionOrderProduct
        )
      ) {
        return this.context.validationErrorsSelectedHouseConnectionOrderProduct;
      } else if (
        fsmStep === 'selectOptions' &&
        hasErrors(this.context.validationErrorsSelectedServiceOrderOptions)
      ) {
        return this.context.validationErrorsSelectedServiceOrderOptions;
      } else {
        return { _validationErrors: [] };
      }
    },
    updateCurrentStep(index) {
      if (index <= this.context.currentHighestAllowedStep) {
        this.context.currentStepIndex = index;
        this.send(this.context.stepperList[index].signalForFsm);
      }
    },
    displayErrors(index) {
      if (index < this.context.currentHighestAllowedStep) {
        return true;
      } else {
        return false;
      }
    },
    jumpToNextStep() {
      this.send('FORWARD');
      if (this.context.currentStepIndex < this.context.stepperList.length - 1) {
        this.context.currentStepIndex++;

        if (
          this.context.currentStepIndex > this.context.currentHighestAllowedStep
        ) {
          this.context.currentHighestAllowedStep =
            this.context.currentStepIndex;
        }
      }
    },
    jumpToPreviousStep() {
      this.send('BACKWARD');
      if (this.context.currentStepIndex > 0) {
        this.context.currentStepIndex--;
      }
    },

    buildOrderDto(context) {
      const orderType = context.selectedOrderType;
      let serviceOrder = null;
      let houseConnectionOrder = null;
      if (
        orderType === 'SERVICE' ||
        orderType === 'HOUSECONNECTION_WITH_SERVICE'
      ) {
        serviceOrder = this.buildServiceOrderDto(context);
      }
      if (
        orderType === 'HOUSECONNECTION' ||
        orderType === 'HOUSECONNECTION_WITH_SERVICE'
      ) {
        houseConnectionOrder = this.buildHouseConnectionOrderDto(context);
      }
      return {
        orderType: orderType,
        agency: {
          company: this.agencyCompandyId
            ? parseInt(this.agencyCompandyId).toString()
            : null,
          employee: this.agencyEmployeeId
            ? parseInt(this.agencyEmployeeId).toString()
            : null,
          channel: this.agencyChannelId
            ? parseInt(this.agencyChannelId).toString()
            : null
        },
        serviceOrder: serviceOrder,
        houseConnectionOrder: houseConnectionOrder,
        orderDate: context.orderDate ? context.orderDate : ''
      };
    },
    buildServiceOrderDto(context) {
      const customerData = context.selectedCustomerData;
      const additionalContactAddressServiceOrder =
        buildContactAddressServiceOrder(customerData);
      const installationAddressServiceOrder =
        buildInstallationAddress(customerData);
      const invoiceAddressServiceOrder =
        buildInvoiceAddressServiceOrder(customerData);
      const deliveryAddressServiceOrder = buildDeliveryAddress(customerData);

      const serviceOrderDto = {
        invoiceAddress: invoiceAddressServiceOrder,
        installationAddress: installationAddressServiceOrder,
        deliveryAddress: deliveryAddressServiceOrder,
        additionalContactAddress: additionalContactAddressServiceOrder,
        uploadedDocuments: context.uploadedFilesServiceOrder,
        productIds: []
      };

      if (customerData.sepaMandateIsSelected && customerData.sepaMandate) {
        serviceOrderDto.sepaMandate = customerData.sepaMandate;
      } else {
        serviceOrderDto.sepaMandate = null;
      }

      if (context.selectedServiceOrderProducts) {
        serviceOrderDto.orderedProducts = this.buildServiceOrderProductDto(
          context.selectedServiceOrderProducts
        );
        serviceOrderDto.productIds = this.collectAllProductIds(
          serviceOrderDto.orderedProducts
        );
      }
      if (context.selectedServiceOrderOptions) {
        Object.assign(
          serviceOrderDto,
          this.buildeServiceOrderOptionsInformation(
            context.selectedServiceOrderOptions
          )
        );
      }

      return serviceOrderDto;
    },
    buildHouseConnectionOrderDto(context) {
      const customerData = context.selectedCustomerData;

      const ownerAddress = buildOwnerAddress(customerData);
      const installationAddress = buildInstallationAddress(customerData);
      const deliveryAddressHouseConnectionOrder =
        buildDeliveryAddress(customerData);

      const invoiceAddressHouseConnectionOrder =
        buildInvoiceAddressHouseConnectionOrder(customerData);
      const constructionCostProductId = this.mapProductId(
        context.selectedHouseConnectionOrderProduct
      );

      return {
        installationAddress: installationAddress,
        invoiceAddress: invoiceAddressHouseConnectionOrder,
        ownerAddress: ownerAddress,
        uploadedDocuments: context.uploadedFilesHouseConnectionOrder,
        deliveryAddress: deliveryAddressHouseConnectionOrder,
        constructionCostProductId: constructionCostProductId
      };
    },
    buildeServiceOrderOptionsInformation(serviceOrderInformation) {
      const serviceOrderOptions = lodash.cloneDeep(serviceOrderInformation);
      if (
        serviceOrderOptions.phoneBookOptions.phoneBookRecordType !== 'NO_ENTRY'
      ) {
        serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress =
          buildAddressDto(
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
              .customerInformation,
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address
          );
      } else {
        serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress = null;
      }
      return serviceOrderOptions;
    },
    buildServiceOrderProductDto(selectedServiceOrderProducts) {
      return {
        oneTimeOrderItemProductIds: this.mapProductIds(
          selectedServiceOrderProducts.oneTimeOrderItemProducts
        ),
        internetProductId: this.mapProductId(
          selectedServiceOrderProducts.internetMainProduct
        ),
        internetOptionProductIds: this.mapProductIds(
          selectedServiceOrderProducts.internetOptionalProducts
        ),
        cpeDeviceProductId: this.mapProductId(
          selectedServiceOrderProducts.cpeDeviceProduct
        ),
        ontDeviceProductId: this.mapProductId(
          selectedServiceOrderProducts.ontDeviceProduct
        ),
        voiceProductId: this.mapProductId(
          selectedServiceOrderProducts.voiceMainProduct
        ),
        voiceOptionProductIds: this.mapProductIds(
          selectedServiceOrderProducts.voiceOptionalProducts
        ),
        tvProductId: this.mapProductId(
          selectedServiceOrderProducts.tvMainProduct
        ),
        tvOptionProductIds: this.mapProductIds(
          selectedServiceOrderProducts.tvOptionalProducts
        ),
        tvDeviceProduct: this.mapProduct(
          selectedServiceOrderProducts.tvDeviceProduct
        )
      };
    },
    mapProductId(singleProduct) {
      return singleProduct ? singleProduct.id : null;
    },
    mapProductIds(multipleProducts) {
      if (multipleProducts) {
        return multipleProducts.map((product) => product.id);
      } else {
        return [];
      }
    },
    collectAllProductIds(orderedProducts) {
      const productIds = [];
      for (const idKey in orderedProducts) {
        if (orderedProducts[idKey] !== null) {
          if (orderedProducts[idKey] instanceof Array) {
            productIds.push(
              ...orderedProducts[idKey].map((id) => {
                return { id: id, quantity: 1 };
              })
            );
          } else if (orderedProducts[idKey] instanceof Object) {
            productIds.push(orderedProducts[idKey]);
          } else {
            productIds.push({ id: orderedProducts[idKey], quantity: 1 });
          }
        }
      }
      return productIds;
    },
    mapProduct(singleProduct) {
      return singleProduct
        ? { id: singleProduct.id, quantity: singleProduct.orderedQuantity }
        : null;
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    getDistributionChannels() {
      this.loading = true;
      HTTP.get('/past/geo/distribution-channels')
        .then((response) => {
          this.agencyChannels = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getDistributionEmployees() {
      this.loading = true;
      HTTP.get('/past/geo/distribution-employees')
        .then((response) => {
          this.agencyEmployees = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getAgencyItemText(item) {
      let label;
      if (
        (this.agencyChannels && this.agencyChannels.length >= 1) ||
        (this.agencyEmployees && this.agencyEmployees.length >= 1)
      ) {
        label = `${item.ID},  ${item.NAME}`;
      }
      return label;
    }
  }
};

const HausanschlussauftragSteps = [
  {
    step: 'Auftragsart',
    fsmStep: 'selectOrderType',
    signalForFsm: 'STEPPER_SELECT_ORDER_TYPE'
  },
  {
    step: 'Kundendaten',
    fsmStep: 'selectCustomerData',
    signalForFsm: 'STEPPER_SELECT_CUSTOMER_DATA'
  },
  {
    step: 'Produkte',
    fsmStep: 'selectHouseConnectionOrderProduct',
    signalForFsm: 'STEPPER_SELECT_HOUSE_CONNECTION_ORDER_PRODUCT'
  },
  {
    step: 'Dokumente',
    fsmStep: 'manageDocuments',
    signalForFsm: 'STEPPER_MANAGE_DOCUMENTS'
  },
  {
    step: 'Übersicht',
    fsmStep: 'confirmSelection',
    signalForFsm: 'STEPPER_CONFIRM_SELECTION'
  }
];
const HausanschlussAndDienstauftragSteps = [
  {
    step: 'Auftragsart',
    fsmStep: 'selectOrderType',
    signalForFsm: 'STEPPER_SELECT_ORDER_TYPE'
  },
  {
    step: 'Kundendaten',
    fsmStep: 'selectCustomerData',
    signalForFsm: 'STEPPER_SELECT_CUSTOMER_DATA'
  },
  {
    step: 'Produkte Diensteauftrag',
    fsmStep: 'selectServiceOrderProducts',
    signalForFsm: 'STEPPER_SELECT_SERVICE_ORDER_PRODUCTS'
  },
  {
    step: 'Produkte Hausanschluss',
    fsmStep: 'selectHouseConnectionOrderProduct',
    signalForFsm: 'STEPPER_SELECT_HOUSE_CONNECTION_ORDER_PRODUCT'
  },
  {
    step: 'Zusatzoptionen Diensteauftrag',
    fsmStep: 'selectOptions',
    signalForFsm: 'STEPPER_SELECT_OPTIONS'
  },
  {
    step: 'Upload Kundendokumente',
    fsmStep: 'manageDocuments',
    signalForFsm: 'STEPPER_MANAGE_DOCUMENTS'
  },
  {
    step: 'Übersicht',
    fsmStep: 'confirmSelection',
    signalForFsm: 'STEPPER_CONFIRM_SELECTION'
  }
];

const DienstauftragSteps = [
  {
    step: 'Auftragsart',
    fsmStep: 'selectOrderType',
    signalForFsm: 'STEPPER_SELECT_ORDER_TYPE'
  },
  {
    step: 'Kundendaten',
    fsmStep: 'selectCustomerData',
    signalForFsm: 'STEPPER_SELECT_CUSTOMER_DATA'
  },
  {
    step: 'Produkte',
    fsmStep: 'selectServiceOrderProducts',
    signalForFsm: 'STEPPER_SELECT_SERVICE_ORDER_PRODUCTS'
  },
  {
    step: 'Zusatzoptionen',
    fsmStep: 'selectOptions',
    signalForFsm: 'STEPPER_SELECT_OPTIONS'
  },
  {
    step: 'Upload Kundendokumente',
    fsmStep: 'manageDocuments',
    signalForFsm: 'STEPPER_MANAGE_DOCUMENTS'
  },
  {
    step: 'Übersicht',
    fsmStep: 'confirmSelection',
    signalForFsm: 'STEPPER_CONFIRM_SELECTION'
  }
];

const stepperList = [
  {
    step: 'Auftragstart',
    fsmStep: 'selectOrderType',
    signalForFsm: 'STEPPER_SELECT_ORDER_TYPE'
  }
];
const dynamicStateTransitions = {
  selectOrderType: 'selectOrderType',
  selectCustomerData: 'selectCustomerData',
  selectServiceOrderProducts: 'selectServiceOrderProducts',
  selectHouseConnectionOrderProduct: 'selectHouseConnectionOrderProduct',
  selectOptions: 'selectOptions',
  manageDocuments: 'manageDocuments',
  confirmSelection: 'confirmSelection',
  resetMachine: 'resetMachine',
  close: 'close'
};

const stepperTransitions = {
  STEPPER_SELECT_ORDER_TYPE: [
    {
      target: 'selectOrderType'
    }
  ],
  STEPPER_SELECT_CUSTOMER_DATA: [
    {
      target: 'selectCustomerData'
    }
  ],
  STEPPER_SELECT_SERVICE_ORDER_PRODUCTS: [
    {
      target: 'selectServiceOrderProducts'
    }
  ],
  STEPPER_SELECT_HOUSE_CONNECTION_ORDER_PRODUCT: [
    {
      target: 'selectHouseConnectionOrderProduct'
    }
  ],
  STEPPER_SELECT_OPTIONS: [
    {
      target: 'selectOptions'
    }
  ],
  STEPPER_MANAGE_DOCUMENTS: [
    {
      target: 'manageDocuments'
    }
  ],
  STEPPER_CONFIRM_SELECTION: [
    {
      target: 'confirmSelection'
    }
  ],
  UPDATE_STEP_INDEX: {
    internal: true,
    actions: [
      (context, event) => {
        context.currentStepIndex = event.payload;
      }
    ]
  }
};

function orderCanBeCanceled(validationErrorsSelectedOrderType) {
  return (
    validationErrorsSelectedOrderType !== null &&
    !hasErrors(validationErrorsSelectedOrderType)
  );
}

function orderCanBeSaved(
  validationErrorsSelectedOrderType,
  validationErrorsSelectedCustomerData
) {
  return (
    validationErrorsSelectedOrderType !== null &&
    !hasErrors(validationErrorsSelectedOrderType) &&
    validationErrorsSelectedCustomerData !== null &&
    validationErrorsSelectedCustomerData.customerInformation &&
    !hasErrors(validationErrorsSelectedCustomerData.customerInformation) &&
    validationErrorsSelectedCustomerData.installationAddress &&
    !hasErrors(validationErrorsSelectedCustomerData.installationAddress)
  );
}

function orderCanBeStarted(
  selectedOrderType,
  validationErrorsOrderDate,
  validationErrorsSelectedCustomerData,
  validationErrorsSelectedServiceOrderProducts,
  validationErrorsSelectedHouseConnectionOrderProduct,
  validationErrorsSelectedServiceOrderOptions
) {
  if (selectedOrderType === 'HOUSECONNECTION') {
    return (
      validationErrorsSelectedCustomerData !== null &&
      !hasErrors(validationErrorsSelectedCustomerData) &&
      validationErrorsSelectedHouseConnectionOrderProduct != null &&
      !hasErrors(validationErrorsSelectedHouseConnectionOrderProduct) &&
      validationErrorsOrderDate !== null &&
      !hasErrors(validationErrorsOrderDate)
    );
  } else {
    return (
      validationErrorsSelectedCustomerData !== null &&
      !hasErrors(validationErrorsSelectedCustomerData) &&
      validationErrorsSelectedServiceOrderProducts !== null &&
      !hasErrors(validationErrorsSelectedServiceOrderProducts) &&
      validationErrorsSelectedServiceOrderOptions !== null &&
      !hasErrors(validationErrorsSelectedServiceOrderOptions) &&
      validationErrorsOrderDate !== null &&
      !hasErrors(validationErrorsOrderDate)
    );
  }
}

function buildDefaultContext(executionContext) {
  return {
    currentExecutionContext: executionContext, // the object one would call 'this' during execution
    displayError: false,
    currentHighestAllowedStep: 0,
    currentStepIndex: 0,
    nextStepButtonLabel: '',
    showDisplayBackButton: false,
    forwardButtonDisabled: false,
    backendCallActive: false,
    orderCanBeCanceled: false,
    orderCanBeSaved: false,
    orderCanBeStarted: false,
    taskId: null,
    selectedOrderType: null,
    validationErrorsSelectedOrderType: null,
    validationOngoing: false,
    selectedCustomerData: null,
    validationErrorsSelectedCustomerData: null,
    emailAddressForServiceOrderInvoiceIsAvailable: false,
    orderDate: null,
    validationErrorsOrderDate: null,
    selectedServiceOrderProducts: null,
    selectedHouseConnectionOrderProduct: null,
    validationErrorsSelectedServiceOrderProducts: null,
    validationErrorsSelectedHouseConnectionOrderProduct: null,
    selectedServiceOrderOptions: null,
    validationErrorsSelectedServiceOrderOptions: null,
    uploadedFilesServiceOrder: null,
    uploadedFilesHouseConnectionOrder: null,
    stepperList: stepperList
  };
}

function buildFsm(executionContext, previousDataSelection) {
  const context = buildDefaultContext(executionContext);
  let initialState = 'selectOrderType';
  if (previousDataSelection) {
    initialState = 'selectCustomerData';
    previousDataSelection.validationErrorsSelectedOrderType = {
      _validationErrors: []
    };
    lodash.assign(context, previousDataSelection);
    // stepperList should changed based on the order type selection
    // currentHighestAllowedStep should be saved
    context.currentHighestAllowedStep = 1;
    context.currentStepIndex = 1;
    if (
      previousDataSelection.selectedOrderType === 'HOUSECONNECTION_WITH_SERVICE'
    ) {
      context.stepperList = HausanschlussAndDienstauftragSteps;
    } else if (previousDataSelection.selectedOrderType === 'HOUSECONNECTION') {
      context.stepperList = HausanschlussauftragSteps;
    } else if (previousDataSelection.selectedOrderType === 'SERVICE') {
      context.stepperList = DienstauftragSteps;
    }
  }
  return createMachine(
    {
      id: 'view',
      initial: initialState,
      context: context,
      states: {
        selectOrderType: {
          entry: assign({
            nextStepButtonLabel: 'Nächster Schritt',
            showDisplayBackButton: false,
            orderCanBeCanceled: (context, event) =>
              orderCanBeCanceled(context.validationErrorsSelectedOrderType),
            orderCanBeSaved: (context, event) =>
              orderCanBeSaved(
                context.validationErrorsSelectedOrderType,
                context.validationErrorsSelectedCustomerData
              ),
            forwardButtonDisabled: !context.orderCanBeCanceled,
            backendCallActive: false
          }),
          on: Object.assign(
            {
              UPDATE_ORDER_TYPE: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.selectedOrderType = event.payload.value;
                    if (context.selectedOrderType === 'HOUSECONNECTION') {
                      context.stepperList = HausanschlussauftragSteps;
                    } else if (
                      context.selectedOrderType ===
                      'HOUSECONNECTION_WITH_SERVICE'
                    ) {
                      context.stepperList = HausanschlussAndDienstauftragSteps;
                    } else if (context.selectedOrderType === 'SERVICE') {
                      context.stepperList = DienstauftragSteps;
                    }
                    context.validationErrorsSelectedOrderType =
                      event.payload.validationErrors;
                    context.orderCanBeCanceled = orderCanBeCanceled(
                      context.validationErrorsSelectedOrderType
                    );
                    context.forwardButtonDisabled = !context.orderCanBeCanceled;
                    context.orderCanBeSaved = orderCanBeSaved(
                      context.validationErrorsSelectedOrderType,
                      context.validationErrorsSelectedCustomerData
                    );
                  }
                ]
              },
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              FORWARD: [
                {
                  target: 'selectCustomerData',
                  cond: 'inputIsValid'
                }
              ]
            },
            stepperTransitions
          )
        },
        selectCustomerData: {
          entry: [
            assign({
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              orderCanBeCanceled: (context, event) =>
                orderCanBeCanceled(context.validationErrorsSelectedOrderType),
              orderCanBeSaved: (context, event) =>
                orderCanBeSaved(
                  context.validationErrorsSelectedOrderType,
                  context.validationErrorsSelectedCustomerData
                ),
              forwardButtonDisabled: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          exit: [
            assign({
              validationOngoing: false
            }),
            (context, event) => {
              const invoiceAddressServiceOrder =
                buildInvoiceAddressServiceOrder(context.selectedCustomerData);

              context.emailAddressForServiceOrderInvoiceIsAvailable =
                !!invoiceAddressServiceOrder.email;

              if (
                context.selectedServiceOrderProducts &&
                !context.emailAddressForServiceOrderInvoiceIsAvailable
              ) {
                context.selectedServiceOrderProducts.invoiceShippingMethod =
                  'POST';
              }
            }
          ],
          on: Object.assign(
            {
              UPDATE_CUSTOMER_DATA: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.selectedCustomerData = event.payload.value;
                    context.validationErrorsSelectedCustomerData =
                      event.payload.validationErrors;
                    context.validationOngoing = event.payload.validationOngoing;
                    context.orderCanBeSaved = orderCanBeSaved(
                      context.validationErrorsSelectedOrderType,
                      context.validationErrorsSelectedCustomerData
                    );
                    context.forwardButtonDisabled = !context.orderCanBeSaved;
                  }
                ]
              },

              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: 'selectOrderType',
              FORWARD: [
                {
                  target: 'selectServiceOrderProducts',
                  cond: 'serviceOrderInformationRequired'
                },
                {
                  target: 'selectHouseConnectionOrderProduct'
                }
              ]
            },
            stepperTransitions
          )
        },
        selectServiceOrderProducts: {
          entry: [
            assign({
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              forwardButtonDisabled: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: Object.assign(
            {
              UPDATE_SERVICE_ORDER_PRODUCTS: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.selectedServiceOrderProducts = event.payload.value;
                    context.validationErrorsSelectedServiceOrderProducts =
                      event.payload.validationErrors;
                  }
                ]
              },
              UPDATE_ORDER_DATE: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.orderDate = event.payload.value;
                    context.validationErrorsOrderDate =
                      event.payload.validationErrors;
                  }
                ]
              },
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: 'selectCustomerData',
              FORWARD: [
                {
                  target: 'selectHouseConnectionOrderProduct',
                  cond: 'houseConnectionOrderInformationRequired'
                },
                {
                  target: 'selectOptions'
                }
              ]
            },
            stepperTransitions
          )
        },
        selectHouseConnectionOrderProduct: {
          entry: [
            assign({
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              forwardButtonDisabled: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: Object.assign(
            {
              UPDATE_HOUSE_CONNECTION_ORDER_PRODUCT: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.selectedHouseConnectionOrderProduct =
                      event.payload.value;
                    context.validationErrorsSelectedHouseConnectionOrderProduct =
                      event.payload.validationErrors;
                  }
                ]
              },

              UPDATE_ORDER_DATE: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.orderDate = event.payload.value;
                    context.validationErrorsOrderDate =
                      event.payload.validationErrors;
                  }
                ]
              },
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: [
                {
                  target: 'selectServiceOrderProducts',
                  cond: 'serviceOrderInformationRequired'
                },
                {
                  target: 'selectCustomerData'
                }
              ],
              FORWARD: [
                {
                  target: 'selectOptions',
                  cond: 'serviceOrderInformationRequired'
                },
                {
                  target: 'manageDocuments'
                }
              ]
            },
            stepperTransitions
          )
        },
        selectOptions: {
          entry: [
            assign({
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              forwardButtonDisabled: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: Object.assign(
            {
              UPDATE_SERVICE_ORDER_OPTIONS: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.selectedServiceOrderOptions = event.payload.value;
                    context.validationErrorsSelectedServiceOrderOptions =
                      event.payload.validationErrors;
                  }
                ]
              },
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: [
                {
                  target: 'selectHouseConnectionOrderProduct',
                  cond: 'houseConnectionOrderInformationRequired'
                },
                {
                  target: 'selectServiceOrderProducts'
                }
              ],
              FORWARD: [
                {
                  target: 'manageDocuments'
                }
              ]
            },
            stepperTransitions
          )
        },
        manageDocuments: {
          entry: [
            assign({
              nextStepButtonLabel: 'Nächster Schritt',
              showDisplayBackButton: true,
              forwardButtonDisabled: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            }
          ],
          on: Object.assign(
            {
              UPDATE_UPLOADED_FILES_SERVICE_ORDER: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.uploadedFilesServiceOrder = event.payload;
                  }
                ]
              },
              UPDATE_UPLOADED_FILES_HOUSE_CONNECTION_ORDER: {
                internal: true,
                actions: [
                  (context, event) => {
                    context.uploadedFilesHouseConnectionOrder = event.payload;
                  }
                ]
              },
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: [
                {
                  target: 'selectOptions',
                  cond: 'serviceOrderInformationRequired'
                },
                {
                  target: 'selectHouseConnectionOrderProduct'
                }
              ],
              FORWARD: 'confirmSelection'
            },
            stepperTransitions
          )
        },
        confirmSelection: {
          entry: [
            assign({
              nextStepButtonLabel: 'Auftrag Starten',
              showDisplayBackButton: true,
              forwardButtonDisabled: true,
              orderCanBeStarted: false
            }),
            (context, event) => {
              window.scroll(0, 0);
            },
            (context, event) => {
              // Validate order && build validation messages
              context.orderCanBeStarted = orderCanBeStarted(
                context.selectedOrderType,
                context.validationErrorsOrderDate,
                context.validationErrorsSelectedCustomerData,
                context.validationErrorsSelectedServiceOrderProducts,
                context.validationErrorsSelectedHouseConnectionOrderProduct,
                context.validationErrorsSelectedServiceOrderOptions
              );
              context.forwardButtonDisabled = !context.orderCanBeStarted;
            }
          ],
          exit: [
            assign({
              orderCanBeStarted: true
            })
          ],
          on: Object.assign(
            {
              CANCEL_ORDER: 'cancelOrder',
              SAVE_ORDER: 'saveOrder',
              BACKWARD: [
                {
                  target: 'manageDocuments'
                }
              ],
              FORWARD: 'startProcess'
            },
            stepperTransitions
          )
        },
        cancelOrder: {
          entry: [
            assign({ backendCallActive: true }),
            (context, event) => {
              if (context.taskId) {
                return context.currentExecutionContext
                  .cancelProcess(context.taskId)
                  .then((response) => {
                    context.currentExecutionContext.showMessage(
                      'success',
                      'Die Daten wurden erfolgreich gelöscht.'
                    );
                    context.currentExecutionContext.send('close');
                  })
                  .catch(() => {
                    context.currentExecutionContext.showMessage(
                      'error',
                      'Die Daten konnten nicht gelöscht werden.'
                    );
                    context.currentExecutionContext.send(event.payload);
                  });
              } else {
                context.currentExecutionContext.send('resetMachine');
              }
            }
          ],
          exit: assign({ backendCallActive: false }),
          on: dynamicStateTransitions
        },
        saveOrder: {
          entry: [
            assign({ backendCallActive: true }),
            (context, event) => {
              let backendCall = null;
              if (context.taskId) {
                backendCall =
                  context.currentExecutionContext.saveHumanTask(context);
              } else {
                backendCall = context.currentExecutionContext
                  .saveNewProcess(context)
                  .then((response) => {
                    // extract process instance id
                    const locationHeader = response.headers.location;
                    const pathElements = locationHeader.split('/');
                    const processId = pathElements[pathElements.length - 1];
                    return processId;
                  })
                  .then((processId) => {
                    // poll task id
                    return POLL(
                      () => {
                        return HTTP.get('/task', {
                          params: { processInstanceId: processId }
                        }).then((response) => {
                          if (response.data.length === 1) {
                            return response.data[0].id;
                          } else {
                            throw new Error('Process is not in task yet.');
                          }
                        });
                      },
                      10,
                      200
                    );
                  })
                  .then((response) => {
                    // store task id
                    context.taskId = response;
                  });
              }
              backendCall
                .then(() => {
                  context.currentExecutionContext.showMessage(
                    'info',
                    'Die Daten wurden erfolgreich gespeichert.'
                  );
                })
                .catch((err) => {
                  console.log('err', err);
                  context.currentExecutionContext.showMessage(
                    'error',
                    'Die Daten konnten nicht gespeichert werden.'
                  );
                })
                .finally(() => {
                  context.currentExecutionContext.send(event.payload);
                });
              return backendCall;
            }
          ],
          exit: assign({ backendCallActive: false }),
          on: dynamicStateTransitions
        },
        startProcess: {
          entry: assign({ backendCallActive: true }),
          exit: assign({ backendCallActive: false }),
          invoke: {
            src: (context, event) => {
              if (context.taskId) {
                return context.currentExecutionContext.finishProcessStartHumanTask(
                  context
                );
              } else {
                return context.currentExecutionContext.startProcess(context);
              }
            },
            onDone: {
              target: 'close',
              actions: function (context) {
                context.currentExecutionContext.showMessage(
                  'success',
                  'Der Prozess wurde erfolgreich gestartet.'
                );
              }
            },
            onError: {
              target: 'confirmSelection',
              actions: function (context) {
                context.currentExecutionContext.showMessage(
                  'error',
                  'Der Prozess konnte nicht gestartet werden.'
                );
              }
            }
          }
        },
        resetMachine: {
          entry: assign((context) =>
            buildDefaultContext(context.currentExecutionContext)
          ),
          always: [{ target: 'selectOrderType' }]
        },
        close: {
          type: 'final',
          exit: function (context) {
            context.currentExecutionContext.$emit('onClose'); // For a task
            if (!context.taskId) {
              context.currentExecutionContext.$router.push('/');
            }
          },
          on: {}
        }
      }
    },
    {
      guards: {
        inputIsValid: (context) => context.forwardButtonDisabled === false,
        serviceOrderInformationRequired: (context) =>
          context.selectedOrderType !== 'HOUSECONNECTION',
        houseConnectionOrderInformationRequired: (context) =>
          context.selectedOrderType !== 'SERVICE'
      }
    }
  );
}
</script>

<style lang="scss" scoped>
.agency-alert ::v-deep .v-text-field__details {
  display: none !important;
}
.font-size-step {
  font-size: small;
}

.components-grid {
  display: grid;
  grid-template-rows: max-content max-content auto max-content;
  height: calc(100% - 2px);
}

.process-step-wrapper {
  display: grid;
  grid-template-columns: auto;
  padding: var(--goe-spacing-2);
}

.align-buttons .col {
  text-align: center;
}

// .align-buttons {
//   display: grid;
//   grid-template-columns: max-content max-content max-content max-content;
//   justify-content: space-between;
//   grid-template-areas: 'left middleleft middleright right';

//   margin-left: var(--goe-spacing-1);
//   margin-right: var(--goe-spacing-1);
// }

// .align-buttons > .button-left {
//   grid-area: left;
// }

// .align-buttons > .button-middle-left {
//   grid-area: middleleft;
// }

// .align-buttons > .button-middle-right {
//   grid-area: middleright;
// }

// .align-buttons > .button-right {
//   grid-area: right;
// }
</style>
