function buildInstallationAddress(customerData) {
  const installationAddress = buildAddressDto(
    customerData.customerInformation,
    customerData.installationAddress
  );
  if (
    customerData.installationAddress
      .alternativeLocationDescriptionIsSelected === true
  ) {
    installationAddress.houseNumber = null;
    installationAddress.houseNumberSupplement = null;
  } else {
    installationAddress.alternativeLocationDescription = null;
  }
  return installationAddress;
}
function buildDeliveryAddress(customerData) {
  const shippingAddressWasProvided =
    customerData.deviatingDeliveryAddressIsSelected;

  if (shippingAddressWasProvided === true) {
    return buildAddressDto(
      customerData.deliveryAddress.customerInformation,
      customerData.deliveryAddress.address
    );
  } else {
    return buildInstallationAddress(customerData);
  }
}
function buildInvoiceAddressServiceOrder(customerData) {
  if (invoiceAddressIsServiceOrderInvoiceAddress(customerData) === true) {
    return buildDeviatingInvoiceAddress(customerData);
  } else {
    return buildInstallationAddress(customerData);
  }
}
function buildContactAddressServiceOrder(customerData) {
  const constactAddressWasProvided = customerData.contactAddressIsSelected;

  if (constactAddressWasProvided === true) {
    return buildAddressDto(
      customerData.contactAddress.customerInformation,
      customerData.contactAddress.address
    );
  } else {
    return null;
  }
}
function buildInvoiceAddressHouseConnectionOrder(customerData) {
  if (
    installationAddressIsHouseConnectionOrderInvoiceAddress(customerData) ===
    true
  ) {
    return buildInstallationAddress(customerData);
  } else if (
    invoiceAddressIsHouseConnectionOrderInvoiceAddress(customerData) === true
  ) {
    return buildDeviatingInvoiceAddress(customerData);
  } else {
    return buildOwnerAddress(customerData);
  }
}
function buildOwnerAddress(customerData) {
  const ownerInformation = customerData.deviatingOwnerInformation;
  const differentOwnerWasProvided =
    customerData.deviatingOwnerInformationIsSelected;
  if (differentOwnerWasProvided === true) {
    return buildAddressDto(
      ownerInformation.customerInformation,
      ownerInformation.address
    );
  } else {
    return buildInstallationAddress(customerData);
  }
}
function buildDeviatingInvoiceAddress(customerData) {
  const invoiceAddressInformation = customerData.invoiceAddress;
  return buildAddressDto(
    invoiceAddressInformation.customerInformation,
    invoiceAddressInformation.address
  );
}
function buildAddressDto(customerInformation, contactAddress) {
  return Object.assign({}, customerInformation, contactAddress);
}

function installationAddressIsServiceOrderInvoiceAddress(customerData) {
  return !invoiceAddressIsServiceOrderInvoiceAddress(customerData);
}

function installationAddressIsHouseConnectionOrderInvoiceAddress(customerData) {
  return (
    invoiceAddressIsHouseConnectionOrderInvoiceAddress(customerData) ===
      false &&
    ownerAddressIsHouseConnectionOrderInvoiceAddress(customerData) === false
  );
}

function invoiceAddressIsServiceOrderInvoiceAddress(customerData) {
  return customerData.invoiceAddressIsSelected;
}

function invoiceAddressIsHouseConnectionOrderInvoiceAddress(customerData) {
  const differentInvoiceAddressWasProvided =
    customerData.invoiceAddressIsSelected;
  const differentOwnerWasProvided =
    customerData.deviatingOwnerInformationIsSelected;
  if (
    differentInvoiceAddressWasProvided === true &&
    (differentOwnerWasProvided === false ||
      ownerAddressIsHouseConnectionOrderInvoiceAddress(customerData) === false)
  ) {
    return true;
  } else {
    return false;
  }
}

function ownerAddressIsHouseConnectionOrderInvoiceAddress(customerData) {
  const ownerPaysForHouseConnection =
    customerData.deviatingOwnerInformation?.ownerPaysForHouseConnection;
  const differentOwnerWasProvided =
    customerData.deviatingOwnerInformationIsSelected;
  if (
    differentOwnerWasProvided === true &&
    ownerPaysForHouseConnection === true
  ) {
    return true;
  } else {
    return false;
  }
}

export {
  buildAddressDto,
  buildInstallationAddress,
  buildInvoiceAddressServiceOrder,
  buildContactAddressServiceOrder,
  buildDeliveryAddress,
  buildInvoiceAddressHouseConnectionOrder,
  buildOwnerAddress,
  installationAddressIsServiceOrderInvoiceAddress,
  installationAddressIsHouseConnectionOrderInvoiceAddress,
  invoiceAddressIsServiceOrderInvoiceAddress,
  invoiceAddressIsHouseConnectionOrderInvoiceAddress,
  ownerAddressIsHouseConnectionOrderInvoiceAddress
};
