<template>
  <div>
    <DisplayContainer
      v-show="orderType !== 'HOUSECONNECTION'"
      label="Dokumente Diensteauftrag"
    >
      <div class="input-row">
        <v-select
          v-model="fileTypeServiceOrder"
          :items="fileTypesServiceOrder"
          :disabled="disabled"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Dokumententyp"
          return-object
        />
        <v-file-input
          v-model="fileServiceOrder"
          :disabled="disabled"
          color="primary"
          accept=".pdf"
          counter
          label="File input"
          dense
          placeholder="PDF Auswählen"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
          @change="handleOnUploadServiceOrder()"
        />
      </div>

      <UploadedDocumentsTable
        :documents="uploadedFilesServiceOrder"
        :documentsCanBeDeleted="true"
        @deleteDocument="
          {
            uploadedFilesServiceOrder = removeDocumentsWithId(
              uploadedFilesServiceOrder,
              $event
            );
            $emit(
              'uploadedFilesServiceOrderChanged',
              uploadedFilesServiceOrder
            );
          }
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-show="orderType !== 'SERVICE'"
      :label="'Dokumente Hausanschlussauftrag'"
    >
      <div class="input-row">
        <v-select
          v-model="fileTypeHouseConnectionOrder"
          :items="fileTypesHouseConnectionOrder"
          :disabled="disabled"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Dokumententyp"
          return-object
        />
        <v-file-input
          v-model="fileHouseConnectionOrder"
          :disabled="disabled"
          color="primary"
          accept=".pdf"
          counter
          label="File input"
          dense
          placeholder="PDF Auswählen"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
          @change="handleOnUploadHouseConnectionOrder()"
        />
      </div>

      <UploadedDocumentsTable
        :documents="uploadedFilesHouseConnectionOrder"
        :documentsCanBeDeleted="true"
        @deleteDocument="
          {
            uploadedFilesHouseConnectionOrder = removeDocumentsWithId(
              uploadedFilesHouseConnectionOrder,
              $event
            );
            $emit(
              'uploadedFilesHouseConnectionOrderChanged',
              uploadedFilesHouseConnectionOrder
            );
          }
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import UploadedDocumentsTable from '@/components/orderEntry/UploadedDocumentsTable.vue';

import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import lodash from 'lodash';

export default {
  name: 'OrderDocumentsUpload',
  components: {
    DisplayContainer,
    UploadedDocumentsTable
  },
  props: {
    orderType: { type: String, required: true },
    previouslyUploadedFilesServiceOrder: {
      type: Array,
      required: false,
      default: () => []
    },
    previouslyUploadedFilesHouseConnectionOrder: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    fileServiceOrder: null,
    fileTypeServiceOrder: null,
    uploadedFilesServiceOrder: [],
    fileTypesServiceOrder: [
      { name: 'Auftrag', value: 'CUSTOMER_ORDER' },
      { name: 'Anbieterwechsel Formular', value: 'FORM_FOR_PROVIDER_CHANGE' },
      { name: 'Lastschriftmandat', value: 'SEPA_MANDATE' },
      { name: 'Andere', value: 'OTHERS' }
    ],
    fileHouseConnectionOrder: null,
    fileTypeHouseConnectionOrder: null,
    uploadedFilesHouseConnectionOrder: [],
    fileTypesHouseConnectionOrder: [
      { name: 'Grundstuecksnutzungsvertrag', value: 'PROPERTY_USE_AGREEMENT' },
      { name: 'Auftrag', value: 'CUSTOMER_ORDER' },
      { name: 'Lastschriftmandat', value: 'SEPA_MANDATE' },
      { name: 'Sonstige', value: 'OTHERS' }
    ]
  }),
  watch: {
    filesHouseConnectionOrder() {
      this.fileHouseConnectionOrder = null;
    }
  },
  created() {
    if (this.previouslyUploadedFilesServiceOrder) {
      this.uploadedFilesServiceOrder = lodash.cloneDeep(
        this.previouslyUploadedFilesServiceOrder
      );
    } else {
      this.uploadedFilesServiceOrder = [];
    }
    if (this.previouslyUploadedFilesHouseConnectionOrder) {
      this.uploadedFilesHouseConnectionOrder = lodash.cloneDeep(
        this.previouslyUploadedFilesHouseConnectionOrder
      );
    } else {
      this.uploadedFilesHouseConnectionOrder = [];
    }
    this.fileTypeServiceOrder = this.fileTypesServiceOrder[0];
    this.fileTypeHouseConnectionOrder = this.fileTypesHouseConnectionOrder[0];
    this.$emit(
      'uploadedFilesServiceOrderChanged',
      this.uploadedFilesServiceOrder
    );
    this.$emit(
      'uploadedFilesHouseConnectionOrderChanged',
      this.uploadedFilesHouseConnectionOrder
    );
  },
  methods: {
    removeDocumentsWithId(documentList, documentId) {
      return documentList.filter(
        (document) => document.documentId !== documentId
      );
    },
    handleOnUploadServiceOrder() {
      this.uploadFile(this.fileServiceOrder)
        .then((fileId) =>
          this.uploadedFilesServiceOrder.push(
            this.buildFileForTable(
              fileId,
              this.fileServiceOrder.name,
              this.fileTypeServiceOrder.value
            )
          )
        )
        .then(() =>
          this.$emit(
            'uploadedFilesServiceOrderChanged',
            this.uploadedFilesServiceOrder
          )
        )
        .finally(() => (this.fileServiceOrder = null));
    },
    handleOnUploadHouseConnectionOrder() {
      this.uploadFile(this.fileHouseConnectionOrder)
        .then((fileId) =>
          this.uploadedFilesHouseConnectionOrder.push(
            this.buildFileForTable(
              fileId,
              this.fileHouseConnectionOrder.name,
              this.fileTypeHouseConnectionOrder.value
            )
          )
        )
        .then(() =>
          this.$emit(
            'uploadedFilesHouseConnectionOrderChanged',
            this.uploadedFilesHouseConnectionOrder
          )
        )
        .finally(() => (this.fileServiceOrder = null));
    },
    buildFileForTable(fileId, fileName, documentType) {
      return {
        documentId: fileId,
        documentName: fileName,
        documentType: documentType,
        uploadDate: new Date(Date.now()).toISOString().slice(0, 10)
      };
    },
    uploadFile(file) {
      const formData = new FormData();
      formData.append('data', file);
      formData.append('fileName', `${file.name}`);

      return HTTP.post('/documentArchive/temporary', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              'Dokument erfolgreich hochgeladen'
            )
          );
          return response.data;
        })
        .catch((error) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject('error', 'Fehler beim Upload. ')
          );
          throw new Error(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
