<template>
  <div>
    <DisplayContainer label="Kontaktinformationen">
      <CustomerDataSummary
        :orderType="orderType"
        :previouslySelectedCustomerData="customerData"
        :validationErrorsCustomerData="validationErrorsSelectedCustomerData"
      />
    </DisplayContainer>
    <DisplayContainer label="Unterschriftsdatum">
      <OrderDateSummary
        :orderDate="orderDate"
        :validationErrorsOrderDate="validationErrorsOrderDate"
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="orderType !== 'HOUSECONNECTION' && selectedServiceOrderOptions"
      label="Ausgewählte Produkte Diensteauftrag"
    >
      <ServiceOrderProductSummary
        :previouslySelectedProducts="selectedServiceOrderProducts"
        :validationErrorsServiceOrderProducts="
          validationErrorsSelectedServiceOrderProducts
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="orderType !== 'SERVICE'"
      label="Ausgewählte Produkte Hausanschlussauftrag"
    >
      <HouseConnectionOrderProductSummary
        :selectedHouseConnectionOrderProduct="
          selectedHouseConnectionOrderProduct
        "
        :validationErrorsSelectedHouseConnectionOrderProduct="
          validationErrorsSelectedHouseConnectionOrderProduct
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="orderType !== 'HOUSECONNECTION' && selectedServiceOrderOptions"
      :label="'Zusatzoptionen Telefonie'"
    >
      <ServiceOrderOptionsSummary
        :previouslySelectedServiceOrderOptions="selectedServiceOrderOptions"
        :validationErrorsServiceOrderOptions="
          validationErrorsSelectedServiceOrderOptions
        "
      />
    </DisplayContainer>

    <DisplayContainer label="Hochgeladene Dokumente">
      <UploadedDocumentsSummary
        :orderType="orderType"
        :previouslyUploadedFilesHouseConnectionOrder="
          previouslyUploadedFilesHouseConnectionOrder
        "
        :previouslyUploadedFilesServiceOrder="
          previouslyUploadedFilesServiceOrder
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import OrderDateSummary from './orderSummary/OrderDateSummary.vue';
import CustomerDataSummary from './orderSummary/CustomerDataSummary.vue';
import ServiceOrderOptionsSummary from './orderSummary/ServiceOrderOptionsSummary.vue';
import ServiceOrderProductSummary from './orderSummary/ServiceOrderProductSummary.vue';
import HouseConnectionOrderProductSummary from './orderSummary/HouseConnectionOrderProductSummary.vue';
import ValidationErrorDisplay from './ValidationErrorDisplay.vue';

import UploadedDocumentsSummary from './orderSummary/UploadedDocumentsSummary.vue';

export default {
  name: 'OrderOverview',
  components: {
    DisplayContainer,
    OrderDateSummary,
    CustomerDataSummary,
    ValidationErrorDisplay,
    ServiceOrderOptionsSummary,
    ServiceOrderProductSummary,
    HouseConnectionOrderProductSummary,
    UploadedDocumentsSummary
  },
  props: {
    orderType: { type: String, required: true },
    customerData: { type: Object, required: true },
    orderDate: { type: String, required: false, default: null },
    validationErrorsOrderDate: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsSelectedCustomerData: { type: Object, required: true },
    selectedServiceOrderProducts: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsSelectedServiceOrderProducts: {
      type: Object,
      required: false,
      default: null
    },
    selectedServiceOrderOptions: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsSelectedServiceOrderOptions: {
      type: Object,
      required: false,
      default: null
    },
    selectedHouseConnectionOrderProduct: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsSelectedHouseConnectionOrderProduct: {
      type: Object,
      required: false,
      default: null
    },
    previouslyUploadedFilesServiceOrder: {
      type: Array,
      required: false,
      default: () => []
    },
    previouslyUploadedFilesHouseConnectionOrder: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>
