var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{attrs:{"label":"Produkte Diensteauftrag"}},[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),_c('AddressDisplay',{attrs:{"addressDto":_vm.installationAddress}}),(!_vm.dataLoading && _vm.productDate)?_c('div',{staticClass:"products-internet"},[_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"Internet Hauptprodukte *"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.internetMainProducts : [],"previousProductSelection":_vm.selectedProducts.internetMainProduct,"singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.internetMainProduct = $event;
          _vm.validationErrors._validationErrors =
            _vm.checkForMissingFields(_vm.selectedProducts);
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"Internet optionale Produkte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.internetOptionalProducts : [],"previousProductSelection":_vm.selectedProducts.internetOptionalProducts,"singleSelect":false},on:{"selectionChanged":function($event){_vm.selectedProducts.internetOptionalProducts = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"Service Produkte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.oneTimeServiceProducts : [],"previousProductSelection":_vm.selectedProducts.oneTimeOrderItemProducts,"isOneTimeProduct":"","singleSelect":false},on:{"selectionChanged":function($event){_vm.selectedProducts.oneTimeOrderItemProducts = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"ONT Geräte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.ontDeviceProducts : [],"previousProductSelection":_vm.selectedProducts.ontDeviceProduct,"isOneTimeProduct":"","singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.ontDeviceProduct = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"CPE Geräte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.cpeDeviceProducts : [],"previousProductSelection":_vm.selectedProducts.cpeDeviceProduct,"singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.cpeDeviceProduct = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"Telefon Hauptprodukte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.voiceMainProducts : [],"previousProductSelection":_vm.selectedProducts.voiceMainProduct,"singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.voiceMainProduct = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"Telefonie Optionen"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.voiceOptionalProducts : [],"previousProductSelection":_vm.selectedProducts.voiceOptionalProducts,"singleSelect":false},on:{"selectionChanged":function($event){_vm.selectedProducts.voiceOptionalProducts = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"TV Hauptprodukte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.tvMainProducts : [],"previousProductSelection":_vm.selectedProducts.tvMainProduct,"singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.tvMainProduct = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{staticClass:"margin-bottom-1 margin-top-1",attrs:{"isSubcomponent":"","label":"TV optionale Produkte"}},[_c('OrderProductTable',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.tvOptionalProducts : [],"previousProductSelection":_vm.selectedProducts.tvOptionalProducts,"singleSelect":false},on:{"selectionChanged":function($event){_vm.selectedProducts.tvOptionalProducts = $event;
          _vm.emitCurrentState();}}})],1),_c('DisplayContainer',{attrs:{"isSubcomponent":"","label":"TV-Gerät"}},[_c('OrderProductTableForMultipleProducts',{attrs:{"availableProducts":_vm.fetchedProducts ? _vm.fetchedProducts.tvDeviceProducts : [],"previousProductSelection":_vm.selectedProducts.tvDeviceProduct,"singleSelect":true},on:{"selectionChanged":function($event){_vm.selectedProducts.tvDeviceProduct = $event;
          _vm.emitCurrentState();}}})],1)],1):(!_vm.productDate)?_c('div'):_c('div',[_c('Loader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }