var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),_c('DisplayContainer',{attrs:{"label":'Einzelverbindungsnachweise (EVN)'}},[_c('v-radio-group',{attrs:{"dense":"","hide-details":"","value":_vm.serviceOrderOptions.itemizedBillsGenerationType},on:{"change":function($event){_vm.serviceOrderOptions.itemizedBillsGenerationType = $event;

        _vm.emitCurrentState();}}},[_c('v-radio',{attrs:{"value":"NO_ITEMIZED_BILLS","label":_vm.itemizedBillTypes['NO_ITEMIZED_BILLS']}}),_c('v-radio',{attrs:{"value":"SHORT_ITEMIZED_BILLS","label":_vm.itemizedBillTypes['SHORT_ITEMIZED_BILLS']}}),_c('v-radio',{attrs:{"value":"FULL_ITEMIZED_BILLS","label":_vm.itemizedBillTypes['FULL_ITEMIZED_BILLS']}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Telefonbucheintrag (kostenlos)"}},[_c('div',{staticClass:"input-row"},[_c('v-radio-group',{attrs:{"value":_vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType,"dense":"","hide-details":""},on:{"change":function($event){_vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType = $event;
          _vm.validationErrors.phoneBookOptions._validationErrors =
            _vm.checkMissingFieldsOfPhoneBookOptions(
              _vm.serviceOrderOptions.phoneBookOptions
            );
          if ($event === 'NO_ENTRY') {
            _vm.serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch = false;
          } else if ($event === 'DEFAULT_ENTRY') {
            _vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              _vm.extractBasicCustomerInformation(_vm.customerInformation);
          }
          if ($event !== 'USER_DEFINED_ENTRY') {
            _vm.validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              { _validationErrors: [] };
          }
          _vm.emitCurrentState();}}},[_c('v-radio',{attrs:{"value":"NO_ENTRY","label":_vm.phoneBookRecordTypes['NO_ENTRY']}}),_c('v-radio',{attrs:{"value":"DEFAULT_ENTRY","label":_vm.phoneBookRecordTypes['DEFAULT_ENTRY']}}),_c('v-radio',{attrs:{"value":"USER_DEFINED_ENTRY","label":_vm.phoneBookRecordTypes['USER_DEFINED_ENTRY']}})],1)],1),_c('div',{staticClass:"v-checkbox"},[_c('v-checkbox',{attrs:{"hide-details":true,"disabled":_vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
            'NO_ENTRY',"dense":"","label":"Der Herausgabe von Teilnehmerdaten für die Rückwärtssuche anhand der\n        Telefonnummern(n) wird zugestimmt."},on:{"change":function($event){return _vm.emitCurrentState()}},model:{value:(_vm.serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch),callback:function ($$v) {_vm.$set(_vm.serviceOrderOptions.phoneBookOptions, "allowPhoneNumberSearch", $$v)},expression:"serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch"}})],1),_c('DisplayContainer',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
          'NO_ENTRY'
      ),expression:"\n        serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==\n          'NO_ENTRY'\n      "}],attrs:{"label":"Telefonbucheintrag","isSubcomponent":true}},[_c('CustomerInformationSelection',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
            'DEFAULT_ENTRY'
        ),expression:"\n          serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===\n            'DEFAULT_ENTRY'\n        "}],staticClass:"margin-top-1",attrs:{"displayError":_vm.displayError,"displayBasicInformation":true,"disabled":true,"previouslySelectedCustomerInformation":_vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
            .customerInformation},on:{"customerInformationChanged":function($event){{
            _vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              $event.value;
            _vm.validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              $event.validationErrors;
            _vm.emitCurrentState();
          }}}}),_c('CustomerInformationSelection',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===
            'USER_DEFINED_ENTRY'
        ),expression:"\n          serviceOrderOptions.phoneBookOptions.phoneBookRecordType ===\n            'USER_DEFINED_ENTRY'\n        "}],staticClass:"margin-top-1",attrs:{"displayError":_vm.displayError,"displayBasicInformation":true,"previouslySelectedCustomerInformation":_vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
            .customerInformation},on:{"customerInformationChanged":function($event){{
            _vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              $event.value;
            _vm.validationErrors.phoneBookOptions.phoneBookEntryAddress.customerInformation =
              $event.validationErrors;
            _vm.emitCurrentState();
          }}}}),_c('AddressAutocomplete',{attrs:{"previouslySelectedAddress":_vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address,"disabled":true},on:{"update":function($event){{
            _vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address =
              $event.value;
            _vm.validationErrors.phoneBookOptions.phoneBookEntryAddress.address =
              $event.validationErrors;
            _vm.emitCurrentState();
          }}}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Rechnungsversand"}},[_c('v-radio-group',{attrs:{"value":_vm.serviceOrderOptions.invoiceShippingMethod,"disabled":!_vm.emailAddressForInvoiceIsAvailable,"dense":"","hide-details":""},on:{"change":function($event){{
          _vm.serviceOrderOptions.invoiceShippingMethod = $event;
          _vm.emitCurrentState();
        }}}},[_c('v-radio',{attrs:{"value":"EMAIL","label":_vm.invoiceShippingMethods['EMAIL']}}),_c('v-radio',{attrs:{"value":"POST","label":_vm.invoiceShippingMethods['POST']}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Löschung der Verkehrsdaten von Telefonverbindungen"}},[_c('v-radio-group',{attrs:{"value":_vm.serviceOrderOptions.storingPeriodOfConnectionInformation,"dense":"","hide-details":""},on:{"change":function($event){_vm.serviceOrderOptions.storingPeriodOfConnectionInformation = $event;
        _vm.emitCurrentState();}}},[_c('v-radio',{attrs:{"value":"SHIPPING_OF_BILL","label":_vm.storingPeriodsOfConnectionInformation['SHIPPING_OF_BILL']}}),_c('v-radio',{attrs:{"value":"PERIOD_OF_EXAMINATION_DEADLINE","label":_vm.storingPeriodsOfConnectionInformation[
            'PERIOD_OF_EXAMINATION_DEADLINE'
          ]}})],1)],1),_c('DisplayContainer',{attrs:{"label":'Information zu Produkten, Dienstleistungen und Unternehmensgeschehen'}},[_c('v-checkbox',{attrs:{"label":"Beratungseinwilligung wurde erteilt","dense":"","hide-details":""},on:{"change":function($event){_vm.serviceOrderOptions.allowAdvertisement = $event;
        _vm.emitCurrentState();}},model:{value:(_vm.serviceOrderOptions.allowAdvertisement),callback:function ($$v) {_vm.$set(_vm.serviceOrderOptions, "allowAdvertisement", $$v)},expression:"serviceOrderOptions.allowAdvertisement"}})],1),_c('DisplayContainer',{attrs:{"label":"Neurufnummernvergabe","selectionConfiguration":{
      label: 'Neurufnummernvergabe durchführen',
      isSelected:
        _vm.serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers,
      isFoldable: true,
      disabled: false
    }},on:{"update:isSelected":function($event){_vm.serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers =
        $event;
      if ($event === true) {
        _vm.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers = 1;
      } else {
        _vm.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers = 0;
      }
      _vm.emitCurrentState();}}},[_c('div',{staticClass:"provider-flex-child"},[_c('v-select',{attrs:{"items":_vm.allowedAmountsOfNewPhoneNumbers,"dense":"","hide-details":"","label":"Anzahl Rufnummern"},on:{"change":function($event){_vm.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers =
            $event;
          _vm.emitCurrentState();}},model:{value:(
          _vm.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers
        ),callback:function ($$v) {_vm.$set(_vm.serviceOrderOptions.newPhoneNumberDetails, "numberOfNewPhoneNumbers", $$v)},expression:"\n          serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers\n        "}})],1),_c('DisplayContainer',{attrs:{"isSubcomponent":true,"label":"Gewünschter Schalttermin *"}},[_c('DatePicker',{attrs:{"errorMessage":_vm.dateErrorMessage,"minDate":_vm.currentDate,"value":_vm.serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate,"disableWeekend":"","label":"Anschaltdatum"},on:{"input":function($event){_vm.serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate =
            $event;
          _vm.emitCurrentState();}}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Portierung Durchführen","selectionConfiguration":{
      label: 'Übernahme eines bestehenden Anschlusses inkl. Rufnummer',
      isSelected: _vm.serviceOrderOptions.performPhoneNumberPorting,
      isFoldable: true,
      disabled: false
    }},on:{"update:isSelected":function($event){_vm.serviceOrderOptions.performPhoneNumberPorting = $event;
      if ($event === false) {
        _vm.serviceOrderOptions.portingDetails = null;
      }
      _vm.validationErrors.portingDetails = { _validationErrors: [] };
      _vm.emitCurrentState();}}},[_c('SelectPortingOptions',{attrs:{"previouslySelectedPortingOptions":_vm.serviceOrderOptions.portingDetails},on:{"portingOptionsChanged":function($event){_vm.serviceOrderOptions.portingDetails = $event.value;
        _vm.validationErrors.portingDetails = $event.validationErrors;
        _vm.emitCurrentState();}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }