<template>
  <div>
    <v-data-table
      :headers="documentTableHeaders"
      :items="documents"
      class="elevation-1 margin-bottom-2"
      hide-default-footer
      dense
    >
      <template v-slot:item.documentType="{ item }">
        <div>
          {{ buildDocumentTypeLabel(item.documentType) }}
        </div>
      </template>
      <template v-slot:item.uploadDate="{ item }">
        <div>
          {{ formatLocalDate(item.uploadDate) }}
        </div>
      </template>
      <template v-slot:item.documentId="{ item }">
        <div>
          <v-btn
            icon
            :disabled="disabled"
            color="primary"
            @click.prevent="downLoadStoredFile(item.documentId)"
          >
            <v-icon> mdi-file-download-outline </v-icon>
          </v-btn>

          <v-btn
            v-if="documentsCanBeDeleted"
            icon
            :disabled="disabled"
            color="primary"
            @click.prevent="$emit('deleteDocument', item.documentId)"
          >
            <v-icon> mdi-delete-forever-outline </v-icon>
          </v-btn>
        </div>
      </template>
      <template slot="no-data">
        Keine Dokumente verfügbar.
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import DocumentUtil from '@/util/DocumentUtil.js';
import TimeUtility from '@/util/TimeUtility.js';
export default {
  name: 'UploadedDocumentsTable',
  props: {
    documents: { type: Array, required: true },
    documentsCanBeDeleted: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      documentTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          value: 'documentName',
          width: '40%'
        },
        { text: 'Typ', value: 'documentType', width: '40%' },
        { text: 'Datum', value: 'uploadDate' },
        { text: '', value: 'documentId', width: '120px' }
      ]
    };
  },
  methods: {
    buildDocumentTypeLabel(documentType) {
      return DocumentUtil.documentTypeLabels[documentType];
    },
    downLoadStoredFile: function (fileId) {
      return HTTP.get(`/documentArchive/content/${fileId}`, {
        responseType: 'blob'
      }).then((response) => {
        const responseHeaders = response.headers;
        const mediaType = responseHeaders['content-type'];
        const fileName = responseHeaders['content-disposition'].split('=')[1];

        const blob = new Blob([response.data], {
          type: mediaType
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    formatLocalDate(date) {
      return TimeUtility.formatLocalDate(date);
    }
  }
};
</script>

<style></style>
