<template>
  <div>
    <DisplayContainer label="Kontaktdaten">
      <CustomerInformationSelection
        :previouslySelectedCustomerInformation="
          customerData.customerInformation
        "
        :disabled="disabled"
        :displayError="displayError"
        @customerInformationChanged="
          customerData.customerInformation = $event.value;
          validationErrors.customerInformation = $event.validationErrors;
          emitCurrentState();
        "
      />
    </DisplayContainer>
    <DisplayContainer label="Installationsadresse">
      <InstallationAddressSelection
        :displayError="displayError"
        :previouslySelectedInstallationAddress="
          customerData.installationAddress
        "
        :customerInformation="customerData.customerInformation"
        :installationAddressIsServiceOrderInvoiceAddress="
          installationAddressIsServiceOrderInvoiceAddress
        "
        :installationAddressIsHouseConnectionOrderInvoiceAddress="
          installationAddressIsHouseConnectionOrderInvoiceAddress
        "
        :orderType="orderType"
        :disabled="true"
        :validationErrorsCustomerInformation="
          validationErrors.customerInformation
        "
        @installationAddressChanged="
          {
            customerData.installationAddress = $event.value;
            validationErrors.installationAddress = $event.validationErrors;
            validationOngoing.installationAddress = $event.validationOngoing;
            emitCurrentState();
          }
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="ibanCanBeInserted"
      label="Lastschriftmandat"
      :selectionConfiguration="{
        label: 'Zahlung per Lastschriftmandat',
        isSelected: customerData.sepaMandateIsSelected,
        isFoldable: true,
        disabled: disabled
      }"
      @update:isSelected="
        customerData.sepaMandateIsSelected = $event;
        validationErrors.sepaMandate = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <SepaMandateSelection
        :customerInformation="customerData.customerInformation"
        :previouslySelectedSepaMandate="customerData.sepaMandate"
        :disabled="disabled"
        :displayError="displayError"
        class="margin-top-1"
        @sepaMandateChanged="
          customerData.sepaMandate = $event.value;
          validationErrors.sepaMandate = $event.validationErrors;
          emitCurrentState();
        "
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="billingAddressCanBeInserted"
      label="Rechnungsadresse"
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.invoiceAddressIsSelected,
        isFoldable: true,
        disabled: disabled
      }"
      @update:isSelected="
        customerData.invoiceAddressIsSelected = $event;
        validationErrors.invoiceAddress = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <InvoiceAddressSelection
        :displayError="displayError"
        :previouslySelectedInvoiceAddress="customerData.invoiceAddress"
        :invoiceAddressIsServiceOrderInvoiceAddress="
          invoiceAddressIsServiceOrderInvoiceAddress
        "
        :invoiceAddressIsHouseConnectionOrderInvoiceAddress="
          invoiceAddressIsHouseConnectionOrderInvoiceAddress
        "
        :disabled="disabled"
        @invoiceAddressChanged="
          customerData.invoiceAddress = $event.value;
          validationErrors.invoiceAddress = $event.validationErrors;
          validationOngoing.invoiceAddress = $event.validationOngoing;
          emitCurrentState();
        "
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="contactAddressCanBeInserted"
      label="Kontaktadresse Diensteauftrag"
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.contactAddressIsSelected,
        isFoldable: true,
        disabled: disabled
      }"
      @update:isSelected="
        customerData.contactAddressIsSelected = $event;
        validationErrors.contactAddress = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <AddressInformationSelection
        :displayError="displayError"
        :previouslySelectedAddressInformation="customerData.contactAddress"
        :disabled="disabled"
        @addressInformationChanged="
          {
            customerData.contactAddress = $event.value;
            validationErrors.contactAddress = $event.validationErrors;
            emitCurrentState();
          }
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="deviationOwnerInformationCanBeInserted"
      label="Versandadresse"
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.deviatingDeliveryAddressIsSelected,
        isFoldable: true,
        disabled: disabled
      }"
      @update:isSelected="
        customerData.deviatingDeliveryAddressIsSelected = $event;
        validationErrors.deliveryAddress = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <AddressInformationSelection
        :displayError="displayError"
        :previouslySelectedAddressInformation="customerData.deliveryAddress"
        :disabled="disabled"
        @addressInformationChanged="
          {
            customerData.deliveryAddress = $event.value;
            validationErrors.deliveryAddress = $event.validationErrors;
            emitCurrentState();
          }
        "
      />
    </DisplayContainer>
    <DisplayContainer
      v-if="deviationOwnerInformationCanBeInserted"
      label="Grundstückseigentümer"
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.deviatingOwnerInformationIsSelected,
        isFoldable: true,
        disabled: disabled
      }"
      @update:isSelected="
        customerData.deviatingOwnerInformationIsSelected = $event;
        validationErrors.deviatingOwnerInformation = { _validationErrors: [] };
        emitCurrentState();
      "
    >
      <OwnerSelection
        :displayError="displayError"
        :previouslySelectedDeviatingOwnerInformation="
          customerData.deviatingOwnerInformation
        "
        :ownerAddressIsHouseConnectionOrderInvoiceAddress="
          ownerAddressIsHouseConnectionOrderInvoiceAddress
        "
        :disabled="disabled"
        @ownerInformationChanged="
          {
            customerData.deviatingOwnerInformation = $event.value;
            validationErrors.deviatingOwnerInformation =
              $event.validationErrors;
            validationOngoing.deviatingOwnerInformation =
              $event.validationOngoing;

            emitCurrentState();
          }
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import lodash from 'lodash';

import AddressInformationSelection from './customerDataSelection/AddressInformationSelection.vue';
import OwnerSelection from './customerDataSelection/OwnerSelection.vue';
import AddressAutocomplete from './customerDataSelection/AddressAutocomplete.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import CustomerInformationSelection from './customerDataSelection/CustomerInformationSelection.vue';
import SepaMandateSelection from './customerDataSelection/SepaMandateSelection.vue';
import InvoiceAddressSelection from './customerDataSelection/InvoiceAddressSelection.vue';
import InstallationAddressSelection from './customerDataSelection/InstallationAddressSelection.vue';
import ValidationErrorDisplay from '../orderCreation/ValidationErrorDisplay.vue';

import {
  installationAddressIsServiceOrderInvoiceAddress,
  installationAddressIsHouseConnectionOrderInvoiceAddress,
  invoiceAddressIsServiceOrderInvoiceAddress,
  invoiceAddressIsHouseConnectionOrderInvoiceAddress,
  ownerAddressIsHouseConnectionOrderInvoiceAddress
} from './CustomerDataUtil.js';

const defaultValues = {
  customerInformation: null,
  installationAddress: null,
  sepaMandateIsSelected: true,
  sepaMandate: null,
  invoiceAddressIsSelected: false,
  invoiceAddress: null,
  contactAddressIsSelected: false,
  contactAddress: null,
  deviatingDeliveryAddressIsSelected: false,
  deliveryAddress: null,
  deviatingOwnerInformationIsSelected: false,
  deviatingOwnerInformation: null
};

export { defaultValues };
export default {
  name: 'CustomerDataSelection',
  components: {
    OwnerSelection,
    AddressAutocomplete,
    AddressInformationSelection,
    ValidationErrorDisplay,
    DisplayContainer,
    CustomerInformationSelection,
    SepaMandateSelection,
    InvoiceAddressSelection,
    InstallationAddressSelection
  },
  props: {
    orderType: {
      // Enum indicating order type -> SERVICE || HOUSECONNECTION || HOUSECONNECTION_WITH_SERVICE
      type: String,
      required: true
    },
    previouslySelectedCustomerData: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    },
    customerInstallAddress: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data: () => ({
    customerData: null,
    validationErrors: null,
    validationOngoing: {
      installationAddress: false,
      invoiceAddress: false,
      deviatingOwnerInformation: false
    }
  }),
  watch: {
    customerInstallAddress: {
      handler: function (val, oldVal) {
        this.customerData.installationAddress = val;
      },
      deep: true
    }
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.birthday);
    },
    ibanCanBeInserted() {
      return this.orderType !== 'HOUSECONNECTION';
    },
    billingAddressCanBeInserted() {
      return this.orderType !== 'HOUSECONNECTION';
    },
    contactAddressCanBeInserted() {
      return this.orderType !== 'HOUSECONNECTION';
    },
    deviationOwnerInformationCanBeInserted() {
      return this.orderType !== 'SERVICE';
    },
    installationAddressIsServiceOrderInvoiceAddress() {
      return installationAddressIsServiceOrderInvoiceAddress(this.customerData);
    },
    installationAddressIsHouseConnectionOrderInvoiceAddress() {
      return installationAddressIsHouseConnectionOrderInvoiceAddress(
        this.customerData
      );
    },
    invoiceAddressIsServiceOrderInvoiceAddress() {
      return invoiceAddressIsServiceOrderInvoiceAddress(this.customerData);
    },
    invoiceAddressIsHouseConnectionOrderInvoiceAddress() {
      return invoiceAddressIsHouseConnectionOrderInvoiceAddress(
        this.customerData
      );
    },
    ownerAddressIsHouseConnectionOrderInvoiceAddress() {
      return ownerAddressIsHouseConnectionOrderInvoiceAddress(
        this.customerData
      );
    }
  },
  created: function () {
    this.validationErrors = this.buildDefaultValidationErrors();

    if (this.$props.previouslySelectedCustomerData) {
      this.customerData = lodash.assign(
        this.buildDefaultCustomerData(),
        lodash.cloneDeep(this.$props.previouslySelectedCustomerData)
      );
    } else {
      this.customerData = this.buildDefaultCustomerData();
    }

    if (this.customerInstallAddress) {
      this.customerData.installationAddress = this.customerInstallAddress;
    }
  },
  methods: {
    buildDefaultCustomerData() {
      return lodash.cloneDeep(defaultValues);
    },
    emitCurrentState() {
      this.$emit('customerDataChanged', {
        value: this.customerData,
        validationErrors: this.validationErrors,
        validationOngoing:
          this.validationOngoing.installationAddress ||
          this.validationOngoing.invoiceAddress ||
          this.validationOngoing.deviatingOwnerInformation
      });
    },
    buildDefaultValidationErrors() {
      return {
        _validationErrors: [],
        customerInformation: null,
        installationAddress: null,
        sepaMandate: { _validationErrors: [] },
        invoiceAddress: { _validationErrors: [] },
        deliveryAddress: { _validationErrors: [] },
        deviatingOwnerInformation: { _validationErrors: [] }
      };
    }
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
