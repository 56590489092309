<template>
  <div class="installationData-wrapper">
    <DisplayContainer label="Auftragstyp">
      <div class="installationData-body">
        <v-card
          v-for="item in orderTypeInformation"
          :key="item.text"
          width="300"
          :disabled="disabled"
          :class="selectedOrderType == item.data ? 'cardColor' : ''"
          :dark="selectedOrderType == item.data ? true : false"
          elevation="1"
          @click="selectedOrderType = item.data"
        >
          <v-card-title>
            <v-icon large>
              mdi-{{ item.icon }}
            </v-icon>
            <span
              v-show="item.icon2"
              class="icon2-wrapper"
            ><span>+</span>
              <v-icon large> mdi-{{ item.icon2 }} </v-icon>
            </span>
          </v-card-title>
          <v-card-text>
            <h4>
              {{ item.text }}
            </h4>
          </v-card-text>
        </v-card>
      </div>
    </DisplayContainer>
  </div>
</template>

<script>
import Checkbox from '@/components/elements/Checkbox.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
export default {
  name: 'OrderTypeSelection',
  components: {
    Checkbox,
    DisplayContainer
  },
  props: {
    previouslySelectedOrderType: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    selectedOrderType: null,
    orderTypeInformation: [
      {
        text: 'Hausanschlussauftrag',
        icon: 'home',
        data: 'HOUSECONNECTION'
      },
      {
        text: 'Hausanschluss & Dienstauftrag',
        icon: 'home',
        icon2: 'connection',
        data: 'HOUSECONNECTION_WITH_SERVICE'
      },
      { text: 'Dienstauftrag', icon: 'connection', data: 'SERVICE' }
    ],
    validationErrors: { _validationErrors: [] }
  }),

  watch: {
    previouslySelectedOrderType: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selectedOrderType = newValue;
        }
      },
      immediate: true,
      deep: true
    },
    selectedOrderType: {
      handler: function (newValue, _) {
        this.validationErrors._validationErrors =
          newValue != null && newValue !== undefined
            ? []
            : 'Es muss ein Auftragstyp ausgewählt werden.';
        this.$emit('orderTypeChanged', {
          value: this.selectedOrderType,
          validationErrors: this.validationErrors
        });
      },
      immediate: true
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.installationData-wrapper {
  display: flex;
  flex-direction: column;
}
.installationData-body {
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin: auto;
}
span.icon2-wrapper > span {
  margin: var(--goe-spacing-em-0) var(--goe-spacing-em-0_5);
}
.v-card__text > h4 {
  font-size: var(--goe-fontSize-large);
}
.buttons-wrapper {
  margin: 0 var(--goe-spacing-0);
  display: flex;
  justify-content: space-between;
}
.buttons-wrapper-no-fill {
  margin-left: auto;
}
.v-card__title,
.v-card__text {
  justify-content: center;
  text-align: center;
}
.cardColor {
  background-color: #1e9cd8;
}
@media screen and (max-width: 1650px) {
  .installationData-body {
    width: 90%;
  }
}
</style>
