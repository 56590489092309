<template>
  <div>
    <ErrorsDisplay>
      <div
        v-for="(error, index) in extractErrors(validatitationErrors)"
        :key="`error-${index}`"
      >
        <span>{{ error }}</span>
      </div>
    </ErrorsDisplay>
  </div>
</template>

<script>
import ErrorsDisplay from '@/components/elements/ErrorDisplay.vue';
import { extractErrors } from './ValidationError.js';

export default {
  components: { ErrorsDisplay },
  props: { validatitationErrors: { type: Object, required: true } },
  methods: {
    extractErrors(validationErrors) {
      return extractErrors(validationErrors);
    }
  }
};
</script>

<style></style>
