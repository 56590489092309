<template>
  <DisplayContainer label="Produkte Hausanschlussauftrag">
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <AddressDisplay :addressDto="installationAddress" />

    <div v-if="!dataLoading" class="products-internet">
      <DisplayContainer
        isSubcomponent
        label="Baukostenzuschuss *"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="fetchedProducts.constructionCostProducts"
          :previousProductSelection="selectedProduct"
          :singleSelect="true"
          :disabled="disabled"
          @selectionChanged="
            selectedProduct = $event;
            validationErrors._validationErrors =
              checkForMissingFields(selectedProduct);
            emitCurrentState();
          "
        />
      </DisplayContainer>
    </div>
    <div v-else>
      <Loader />
    </div>
  </DisplayContainer>
</template>

<script>
import lodash from 'lodash';

import OrderProductTable from './productSelection/OrderProductTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/Loader.vue';
import { HTTP } from '@/main/httpClient.js';
import { hasErrors } from './ValidationError.js';
import ValidationErrorDisplay from './ValidationErrorDisplay.vue';

const defaultValue = null;

export { defaultValue };
export default {
  name: 'HouseConnectionOrderProductSelection',
  components: {
    DisplayContainer,
    AddressDisplay,
    NormalButton,
    Loader,
    OrderProductTable,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedProduct: {
      type: Object,
      required: false,
      default: null
    },
    previouslySelectedProductDate: {
      type: String,
      required: false,
      default: null
    },
    installationAddress: {
      type: Object,
      required: true
    },
    disabled: { type: Boolean, required: false, deafult: false },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    selectedProduct: null,
    fetchedProducts: null,
    dataLoading: false,
    productDate: null
  }),
  created() {
    this.productDate = this.previouslySelectedProductDate;

    if (this.previouslySelectedProduct) {
      this.selectedProduct = lodash.cloneDeep(this.previouslySelectedProduct);
    } else {
      this.selectedProduct = this.buildDefaultSelection();
    }
    this.fetchProducts();
    this.validationErrors = this.buildInitialValidationErrors(
      this.selectedProduct
    );
    this.emitCurrentState();
  },
  methods: {
    buildInitialValidationErrors(selectedProducts) {
      const errorMessages = this.checkForMissingFields(selectedProducts);
      return { _validationErrors: errorMessages };
    },
    checkForMissingFields(selectedProduct) {
      const errorMessages = [];
      if (!selectedProduct) {
        errorMessages.push(
          'Es muss ein Produkt für den Hauskostenzuschuss ausgewählt werden.'
        );
      }
      return errorMessages;
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    emitCurrentState() {
      this.$emit('productSelectionChanged', {
        value: this.selectedProduct,
        validationErrors: this.validationErrors
      });
    },
    fetchProducts() {
      this.dataLoading = true;
      HTTP.get('/products/availableHouseConnectionOrderProducts', {
        params: {
          date: this.productDate,
          zipCode: this.$props.installationAddress.zipCode,
          street: this.$props.installationAddress.street,
          houseNumber: this.$props.installationAddress.houseNumber
        }
      })
        .then((result) => {
          this.fetchedProducts = result.data;
        })
        .catch((e) => {
          console.error('Error: ', e);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    buildDefaultSelection() {
      return lodash.cloneDeep(defaultValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.products-internet {
  display: flex;
  flex-direction: column;
  animation: fade-in 0.5s ease-out;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(5em);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
