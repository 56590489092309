import lodash from 'lodash';

function hasErrors(validationError) {
  if (validationError === null || validationError === undefined) {
    // In case the value to check is null it was not initialized properly Check if this makes sense
    return false;
  } else if (Array.isArray(validationError)) {
    return validationError.some((error) => hasErrors(error));
  } else if (validationError._validationErrors.length !== 0) {
    return true;
  } else {
    const objectKeys = Object.keys(validationError);
    if (objectKeys.length === 1) {
      return false;
    } else {
      return objectKeys.some((key) =>
        key === '_validationErrors' ? false : hasErrors(validationError[key])
      );
    }
  }
}

function flattenAllErrors(validationError) {
  return { _validationErrors: extractAllErrors(validationError) };
}

function extractAllErrors(validationError) {
  if (validationError === null || validationError === undefined) {
    // In case the value to check is null it was not initialized properly Check if this makes sense
    return [];
  } else if (Array.isArray(validationError)) {
    return validationError.flatMap((error) => error._validationErrors);
  } else {
    const objectKeys = Object.keys(validationError);

    return lodash.flatten(
      objectKeys.map((key) => {
        if (key === '_validationErrors' && validationError[key]) {
          return validationError[key];
        } else {
          return extractAllErrors(validationError[key]);
        }
      })
    );
  }
}

function extractErrors(validationError) {
  if (validationError === null || validationError === undefined) {
    // In case the value to check is null it was not initialized properly Check if this makes sense
    return [];
  } else if (Array.isArray(validationError)) {
    return validationError.flatMap((error) => error._validationErrors);
  } else {
    const objectKeys = Object.keys(validationError);

    return lodash.flatten(
      objectKeys.map((key) => {
        if (key === '_validationErrors' && validationError[key]) {
          return validationError[key];
        } else if (
          validationError[key] &&
          validationError[key]._validationErrors
        ) {
          return validationError[key]._validationErrors;
        }
      })
    );
  }
}

function extractValidationErrorsOfObject(validationError) {
  const clonedValidationErrors = lodash.cloneDeep(validationError);

  const objectKeys = Object.keys(clonedValidationErrors);
  objectKeys.forEach((objectKey) => {
    if (
      objectKey !== '_validationErrors' &&
      (clonedValidationErrors[objectKey] === null ||
        Object.keys(clonedValidationErrors[objectKey]).length !== 1) // an object, not a leaf node
    ) {
      delete clonedValidationErrors[objectKey];
    }
  });
  return clonedValidationErrors;
}

function customizer(objValue, srcValue) {
  if (lodash.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

function mergeValidationErrors(validationError1, validationError2) {
  return lodash.mergeWith({}, validationError1, validationError2, customizer);
}

export {
  hasErrors,
  extractErrors,
  flattenAllErrors,
  extractValidationErrorsOfObject,
  mergeValidationErrors
};
