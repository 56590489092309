<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <DisplayContainer label="Unterschriftsdatum">
      <DatePicker
        v-model="signatureDate"
        :dense="true"
        :disabled="disabled"
        :errorMessage="
          signatureDate === null ||
            signatureDate === '' ||
            signatureDate === undefined
            ? 'Es muss ein Unterschriftsdatum ausgewählt werden.'
            : null
        "
        :maxDate="latestAllowedSignatureDate"
        label="Datum der Unterschrift"
        placeholder=""
        @input="emitCurrentState()"
      />
    </DisplayContainer>
  </div>
</template>

<script>
import Checkbox from '@/components/elements/Checkbox.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import { hasErrors } from './ValidationError.js';
import ValidationErrorDisplay from './ValidationErrorDisplay.vue';
import { LocalDate } from '@js-joda/core';

export default {
  name: 'SignatureDateSelection',
  components: {
    Checkbox,
    DatePicker,
    DisplayContainer,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedSignatureDate: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    signatureDate: null,
    validationErrors: null,
    latestAllowedSignatureDate: LocalDate.now().toString()
  }),
  created: function () {
    this.validationErrors = this.buildDefaultValidationErrors();

    if (this.$props.previouslySelectedSignatureDate) {
      this.signatureDate = this.$props.previouslySelectedSignatureDate;
    } else {
      this.signatureDate = '';
    }
    this.emitCurrentState();
  },
  methods: {
    buildDefaultSignatureDate() {
      return LocalDate.now().toString();
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildDefaultValidationErrors() {
      return { _validationErrors: [] };
    },
    checkForMissingFields: function () {
      const missingFields = [];
      if (
        this.signatureDate === null ||
        this.signatureDate === undefined ||
        this.signatureDate === ''
      ) {
        missingFields.push('Es muss ein Auftragsdatum ausgewählt werden.');
      }
      return missingFields;
    },
    emitCurrentState: function () {
      this.validationErrors._validationErrors = this.checkForMissingFields();
      this.$emit('signatureDateChanged', {
        value: this.signatureDate,
        validationErrors: this.validationErrors
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
