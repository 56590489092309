<template>
  <DisplayContainer label="Produkte Diensteauftrag">
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <AddressDisplay :addressDto="installationAddress" />
    <div v-if="!dataLoading && productDate" class="products-internet">
      <DisplayContainer
        isSubcomponent
        label="Internet Hauptprodukte *"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.internetMainProducts : []
          "
          :previousProductSelection="selectedProducts.internetMainProduct"
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.internetMainProduct = $event;
            validationErrors._validationErrors =
              checkForMissingFields(selectedProducts);
            emitCurrentState();
          "
        />
      </DisplayContainer>

      <DisplayContainer
        isSubcomponent
        label="Internet optionale Produkte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.internetOptionalProducts : []
          "
          :previousProductSelection="selectedProducts.internetOptionalProducts"
          :singleSelect="false"
          @selectionChanged="
            selectedProducts.internetOptionalProducts = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <DisplayContainer
        isSubcomponent
        label="Service Produkte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.oneTimeServiceProducts : []
          "
          :previousProductSelection="selectedProducts.oneTimeOrderItemProducts"
          isOneTimeProduct
          :singleSelect="false"
          @selectionChanged="
            selectedProducts.oneTimeOrderItemProducts = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <DisplayContainer
        isSubcomponent
        label="ONT Geräte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.ontDeviceProducts : []
          "
          :previousProductSelection="selectedProducts.ontDeviceProduct"
          isOneTimeProduct
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.ontDeviceProduct = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <DisplayContainer
        isSubcomponent
        label="CPE Geräte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.cpeDeviceProducts : []
          "
          :previousProductSelection="selectedProducts.cpeDeviceProduct"
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.cpeDeviceProduct = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <DisplayContainer
        isSubcomponent
        label="Telefon Hauptprodukte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.voiceMainProducts : []
          "
          :previousProductSelection="selectedProducts.voiceMainProduct"
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.voiceMainProduct = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <DisplayContainer
        isSubcomponent
        label="Telefonie Optionen"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.voiceOptionalProducts : []
          "
          :previousProductSelection="selectedProducts.voiceOptionalProducts"
          :singleSelect="false"
          @selectionChanged="
            selectedProducts.voiceOptionalProducts = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <!-- TV Main products -->
      <DisplayContainer
        isSubcomponent
        label="TV Hauptprodukte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.tvMainProducts : []
          "
          :previousProductSelection="selectedProducts.tvMainProduct"
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.tvMainProduct = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <!-- TV optional products -->
      <DisplayContainer
        isSubcomponent
        label="TV optionale Produkte"
        class="margin-bottom-1 margin-top-1"
      >
        <OrderProductTable
          :availableProducts="
            fetchedProducts ? fetchedProducts.tvOptionalProducts : []
          "
          :previousProductSelection="selectedProducts.tvOptionalProducts"
          :singleSelect="false"
          @selectionChanged="
            selectedProducts.tvOptionalProducts = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
      <!-- TV device products -->
      <DisplayContainer isSubcomponent label="TV-Gerät">
        <OrderProductTableForMultipleProducts
          :availableProducts="
            fetchedProducts ? fetchedProducts.tvDeviceProducts : []
          "
          :previousProductSelection="selectedProducts.tvDeviceProduct"
          :singleSelect="true"
          @selectionChanged="
            selectedProducts.tvDeviceProduct = $event;
            emitCurrentState();
          "
        />
      </DisplayContainer>
    </div>
    <div v-else-if="!productDate"></div>
    <div v-else>
      <Loader />
    </div>
  </DisplayContainer>
</template>

<script>
import lodash from 'lodash';

import OrderProductTable from './productSelection/OrderProductTable.vue';
import OrderProductTableForMultipleProducts from './productSelection/OrderProductTableForMultipleProducts.vue';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/Loader.vue';
import { HTTP } from '@/main/httpClient.js';
import { hasErrors } from './ValidationError.js';
import ValidationErrorDisplay from './ValidationErrorDisplay.vue';

const defaultValues = {
  oneTimeOrderItemProducts: [],
  internetMainProduct: null,
  internetOptionalProducts: [],
  cpeDeviceProduct: null,
  ontDeviceProduct: null,
  voiceMainProduct: null,
  voiceOptionalProducts: [],
  tvMainProduct: null,
  tvOptionalProducts: [],
  tvDeviceProduct: null
};

export { defaultValues };
export default {
  name: 'ServiceOrderProductSelection',
  components: {
    DisplayContainer,
    AddressDisplay,
    NormalButton,
    Loader,
    OrderProductTable,
    ValidationErrorDisplay,
    OrderProductTableForMultipleProducts
  },
  props: {
    previouslySelectedProducts: {
      type: Object,
      required: false,
      default: null
    },
    previouslySelectedProductDate: {
      type: String,
      required: true
    },
    installationAddress: {
      type: Object,
      required: true
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    selectedProducts: null,
    fetchedProducts: null,
    dataLoading: false,
    productDate: null
  }),
  watch: {
    previouslySelectedProductDate: function (newValue, _) {
      this.productDate = newValue;
      this.fetchProducts();
    }
  },
  created() {
    this.productDate = this.previouslySelectedProductDate
      ? this.previouslySelectedProductDate
      : '';

    if (this.previouslySelectedProducts) {
      this.selectedProducts = lodash.cloneDeep(this.previouslySelectedProducts);
    } else {
      this.selectedProducts = this.buildDefaultSelection();
    }
    this.fetchProducts();
    this.validationErrors = this.buildInitialValidationErrors(
      this.selectedProducts
    );
    this.emitCurrentState();
  },
  methods: {
    buildInitialValidationErrors(selectedProducts) {
      const errorMessages = this.checkForMissingFields(selectedProducts);
      return { _validationErrors: errorMessages };
    },
    checkForMissingFields(selectedProducts) {
      const errorMessages = [];
      if (!selectedProducts.internetMainProduct) {
        errorMessages.push(
          'Es muss ein Internet Hauptprodukt ausgewählt werden.'
        );
      }
      return errorMessages;
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    emitCurrentState() {
      this.$emit('productSelectionChanged', {
        value: this.selectedProducts,
        validationErrors: this.validationErrors
      });
    },
    fetchProducts() {
      if (this.productDate) {
        this.dataLoading = true;
        HTTP.get('/products/availableServiceOrderProducts', {
          params: {
            date: this.productDate,
            zipCode: this.$props.installationAddress.zipCode,
            street: this.$props.installationAddress.street,
            houseNumber: this.$props.installationAddress.houseNumber
          }
        })
          .then((result) => {
            this.fetchedProducts = result.data.availableProducts
              ? result.data.availableProducts
              : [];
          })
          .catch((e) => {
            console.error('Error: ', e);
          })
          .finally(() => {
            this.dataLoading = false;
          });
      } else {
        this.dataLoading = false;
      }
    },

    buildDefaultSelection() {
      return lodash.cloneDeep(defaultValues);
    }
  }
};
</script>

<style lang="scss" scoped>
.products-internet {
  display: flex;
  flex-direction: column;
  animation: fade-in 0.5s ease-out;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(5em);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
